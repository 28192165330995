@use "../../../common/scss/colors";
@use "../../../common/scss/font";

.wheel-container {
  padding: 0.625rem 1.25rem;

  &__wheel {
    flex-direction: row;
    display: flex;
    box-sizing: border-box;
    flex: 1;
    width: 100%;
    padding: 0 0.625rem;
  }

  &__selected-row {
    width: calc(100% - 2.5rem);
    box-sizing: border-box;
    height: 2.5rem;
    background: colors.$light_purple;
    position: absolute;
    border-radius: 0.75rem;
    transform: translateY(-7.5rem);
  }

  &__save-date {
    border-radius: 1.5rem;
    background-color: colors.$blue;
    justify-content: center;
    align-items: center;
    color: colors.$white;
    width: 100%;
    border: none;
    margin: 1rem 0;
    padding: 0.5625rem 2rem;
    font-size: font.$size--big;
  }
}
