.grayListBanner {
  line-height: 1.5rem;

  &__title {
    font-weight: 700;
  }

  &__readMoreLink {
    text-decoration: underline;
    cursor: pointer;
    line-height: 2rem;
  }

  &__browserList {
    margin: 1rem;
  }
}
