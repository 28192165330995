/**
 * Z-Index Layer Variables
 * 
 * These variables define the z-index levels used across the application
 * to manage the stacking order of elements. By centralizing the z-index
 * values, we ensure consistent layering of UI components and avoid conflicts.
 */

$base: 0;
$base-five: 5;
$content: 10;
$content-five: 15;
$nav: 20;
$nav-content: 21;
$nav-button: 22;
$dropdown: 30;
$dropdown-five: 35;
$backdrop: 40;
$backdrop-five: 45;
$panel: 50;
$panel-five: 55;
$modal: 60;
$modal-five: 65;
$toast: 70;
$toast-five: 75;
$top: 100;
