@use "../../scss/colors";
@use "../../scss/font";
@use "../../scss/mixins";

%title {
  border-radius: 0.25rem;
  font-size: font.$size--standard;
  font-weight: font.$weight--regular;
  display: flex;
  width: fit-content;
  align-items: center;
  margin-bottom: 0 !important;
  text-wrap: nowrap;
}

.awardProgressBadge {
  min-width: 2rem;
  min-height: 12rem;
  height: 100%;
  width: 100%;
  border: 1px solid colors.$light_purple;
  display: flex;
  flex-direction: column;

  &__title {
    @extend %title;

    &--achieved {
      color: colors.$light_green;
      background-color: colors.$light_green_bg;
      padding: 0.15rem 0.5rem;

      *:first-child {
        margin-right: 0.25rem;
        margin-left: -0.15rem;
      }
    }

    &--tracking {
      @extend %title;

      color: colors.$blue;
      background-color: colors.$light_purple;
      padding: 0.258rem 0.5rem;
      height: 1.675rem;

      * > svg {
        padding: 0.05rem;
        margin-right: 0.25rem;
      }

      @include mixins.md_s {
        text-wrap: wrap;
        height: auto;
      }
    }

    &--notTracking {
      @extend %title;

      color: colors.$disabled_gray;
      background-color: colors.$light_gray_bg;
      padding: 0.26rem 0.5rem;

      *:first-child {
        padding: 0.25rem;
        margin-right: 0.25rem;
      }
    }
  }
}
