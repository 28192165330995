@use "./common/scss/colors";
@use "./common/scss/font";
@use "./common/scss/mixins";
@use "./common/scss/font-imports";
@use "./common/scss/layers";

:root {
  --color__mine_shaft: colors.$mine_shaft;
  --color__mine_shaft_70: colors.$mine_shaft_70;
  --color__dark_grey: colors.$dark_grey;
  --color__blue: colors.$blue;
  --color__light_background: colors.$light_background;
  --color__white: colors.$white;
  --color__black: colors.$black;
  --color__amway_black: colors.$amway_black;
  --color__light_gray: colors.$light_gray;
  --color__light_purple: colors.$light_purple;
  --acc-theme-font-heading1: 2.441rem;
  --acc-theme-font-heading2: 1.935rem;
  --acc-theme-font-heading3: 1.563rem;
  --acc-theme-font-heading4: 1.25rem;
  --acc-theme-font-heading5: 1rem;
  --acc-theme-font-heading6: 0.8rem;
  --acc-theme-font-small: 0.8rem;
  --acc-theme-front-sm-md: 0.875rem;
  --acc-theme-font-large: 1.5rem;
  --acc-theme-font-extra-large: 3rem;
  --acc-theme-font-scale: 100%;
  --acc-theme-layout-01: 1rem;
  --acc-theme-layout-02: 1.5rem;
  --acc-theme-layout-03: 2rem;
  --acc-theme-layout-04: 3rem;
  --acc-theme-layout-05: 4rem;
  --acc-theme-layout-06: 6rem;
  --acc-theme-spacing-01: 0.129rem;
  --acc-theme-spacing-02: 0.216rem;
  --acc-theme-spacing-03: 0.36rem;
  --acc-theme-spacing-04: 0.6rem;
  --acc-theme-spacing-05: 1rem;
  --acc-theme-spacing-06: 1.667rem;
  --acc-theme-spacing-07: 2.779rem;
  --acc-theme-spacing-08: 4.632rem;
  --font-heading6: var(--acc-theme-font-heading6, 0.8rem);

  // https://sass-lang.com/documentation/breaking-changes/css-vars
  --ar-title-font-family: #{font.$family--primary};
  --los-title-font-family: #{font.$family--primary};
  --los-title-margin-mobile: 0.75rem 0 0.75rem 1rem;
  --ar-title-margin-mobile: 0.75rem 0 0.75rem 1rem;
}

*,
*::before,
*::after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  cursor: default;
  font-family: font.$family--primary !important;
  transition: transform 0.3s ease-in-out;

  // disable elastic scrolling on iOS
  overscroll-behavior: none !important;
  -webkit-overflow-scrolling: none !important;
}

*::-webkit-scrollbar {
  background-color: transparent;
  width: 0.313rem;
  border-left: 0.5rem solid transparent;
}

*::-webkit-scrollbar-track {
  background-color: colors.$light_gray;
  border-radius: 1rem;
  margin: 0.5rem;
}

*::-webkit-scrollbar-thumb {
  background: rgb(112 112 112 / 50%);
  border-radius: 1rem;
}

a,
a:hover,
a > * {
  cursor: pointer !important;
}

a:link,
a:visited,
a:hover,
a > * {
  text-decoration: none;
  color: inherit;
  cursor: pointer !important;
}

body {
  height: 100% !important;
  min-width: 23.125rem;
  font-size: 1rem;
  display: flex;
  color: colors.$amway_black;
}

#webpack-dev-server-client-overlay {
  display: none !important;
}

#root {
  height: 100%;
  width: 100%;
  max-width: 100% !important;
}

html {
  font-size: 16px; /* 1rem = 16px */
  height: 100%;
}

.App {
  background-color: colors.$white;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-height: 100vh;
  height: 100%;

  &__footer {
    height: auto;
    width: 100%;
    align-self: flex-end;
  }

  &__header {
    display: block;
  }

  &__banner {
    @include mixins.less_than_desktop_inclusive {
      margin-top: -0.625rem;
    }
  }

  &__component-section {
    max-height: 100vh;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  &__spacing {
    margin: 1.875rem 0;
  }

  &__debug-menu {
    display: flex;
    justify-content: center;
  }

  h4 {
    font-size: 1.25rem;
  }

  /* preload images to have them ready + cached across the site */
  &::before {
    content: "";
    position: absolute;
    width: 0;
    height: 0;
    overflow: hidden;
    visibility: hidden;
    background: url("../public/images/checkbox.svg");
  }
}

.ada-button-frame {
  z-index: layers.$content-five !important;
}

.ada-chat-frame {
  z-index: layers.$content-five !important;
}
