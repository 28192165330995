.icon-plain-table {
  display: block;
  height: 1rem;
  width: 1.125rem;
  cursor: pointer;

  &.blue {
    cursor: pointer;
  }

  &.purple {
    cursor: pointer;
  }
}
