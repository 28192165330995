@use "../../scss/colors";

.leaf-checkbox {
  display: flex;
  align-items: center;
  gap: 0.875rem;
  font-size: 1rem;
  padding: 0.625rem 0;
  line-height: 1.5rem;

  input[type="checkbox"] {
    /* removing default appearance */
    appearance: none;

    /* creating a custom design */
    width: 1.25rem;
    height: 1.25rem;
    border-radius: 0.15em;
    margin-right: 0.5em;
    cursor: pointer;
    border: solid 2px;
    border-color: colors.$amway_black;

    &:hover {
      border-color: colors.$blue;
    }
  }

  input.checked {
    background: url("../../../../public/images/checkbox.svg") no-repeat;
    background-size: contain;
    border: none;
  }

  input[type="checkbox"]:disabled {
    cursor: default;
    border-color: colors.$disabled;
  }

  &__label {
    cursor: pointer;

    &--disabled {
      color: colors.$disabled;
      cursor: default;
    }
  }
}
