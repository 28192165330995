@use "../../../../../common/scss/colors";
@use "../../../../../common/scss/font";
@use "../../../../../common/scss/mixins";

.FaaTrackingTile {
  display: flex;
  flex-direction: column;

  &__subtitle {
    font-size: font.$size--small;
    margin: 0.75rem 0;
  }

  &__subtitle span {
    font-weight: font.$weight--bold;
  }

  &__data--section {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 0.75rem;
    margin-top: 0.75rem;

    @include mixins.lg_s {
      font-size: font.$size--medium !important;
    }
  }

  &__row-label {
    padding: 0.3125rem 0;
    color: colors.$dark_grey_on_white;
  }

  &__row-value {
    padding: 0.3125rem 0;
    display: flex;
    text-align: right;
    color: colors.$amway_black;
    font-weight: bold;
    justify-content: flex-end;

    .awardProgressBadge__title {
      height: fit-content;
      text-wrap: pretty !important;
      text-align: center;

      @include mixins.lg_s {
        font-size: font.$size--medium !important;
      }
    }
  }

  &__data--row {
    display: flex;
    justify-content: space-between;
    gap: 0.4rem;

    .awardProgressBadge__title {
      text-wrap: pretty !important;
    }
  }

  &__dbrStatus {
    padding-bottom: 0;
  }

  &__data--row p {
    color: colors.$dark_grey_on_white;

    @include mixins.md_s {
      min-width: 50%;
    }
  }

  &__data--row span {
    font-weight: font.$weight--bold;
  }
}
