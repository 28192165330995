@use "../../../../../../../common/scss/font";
@use "../../../../../../../common/scss/colors";
@use "../../../../../../../common/scss/mixins";

.abo-profile-perf-history {
  &__title {
    font-size: font.$size--standard;
    font-weight: font.$weight--bold;
    padding: 1.5rem 0 1rem;
  }

  &__options {
    gap: 0.5rem;
    display: flex;
    flex-direction: column;
    font-weight: font.$weight--medium;
    font-size: font.$size--medium;

    &__kpis,
    &__py {
      display: flex;
      flex-direction: column;
      gap: 0.25rem;
    }

    &__kpis {
      min-width: 14.5rem;

      @include mixins.md {
        min-width: 17rem;
      }
    }

    &__py {
      min-width: 6.1rem;
    }
  }

  &__content {
    &__data {
      &__chart {
        height: 11.5rem;
      }

      &__table {
        margin-top: 1rem;
      }
    }

    &__error {
      display: none;

      &--show {
        display: block;
        padding: 10rem 1rem;
      }
    }
  }
}
