@use "../../common/scss/mixins";
@use "../../common/scss/animations";

.componentLoader {
  &__row {
    @extend %loading-pulse;

    height: 10rem;
    margin: 1rem;
  }

  &__title {
    @extend %loading-pulse;

    height: 2.3125rem;
    width: 25rem;
    margin: 1.5rem 1rem;

    @include mixins.md_l {
      height: 1.6875rem;
      width: 20rem;
      margin: 0.75rem 1rem;
    }
  }
}
