@use "../../scss/colors";
@use "../../scss/layers";

.leaf-nav-dropdown {
  display: flex;
  flex-direction: column;
  position: relative;
  margin: 1.25rem 0;
  width: 100%;

  * > {
    font-size: 0.875rem;
    font-weight: 500;
  }

  &__dropdown {
    background-color: colors.$light_purple;
    border-radius: 0.625rem;
    width: 100%;
    justify-content: center;
    position: relative;
    min-height: 2.25rem;

    &-title {
      color: colors.$blue;
      align-self: center;
      position: absolute;
      top: 25%;
      left: 5%;
      width: 100%;
    }

    &-arrow {
      position: absolute;
      top: 40%;
      left: 90%;
      display: inline-block;
      transform-origin: center;
      transform: translateX(25%) rotate(-0.5turn);

      span {
        height: 0.4375rem;
        width: 0.9375rem;
      }
    }
  }

  &__checkbox[type="checkbox"] {
    z-index: layers.$content;
    opacity: 0;
    min-height: 2.25rem;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }

  &__checkbox[type="checkbox"]:hover {
    cursor: pointer;
  }

  &__checkbox[type="checkbox"]:checked ~ &__dropdown > &__dropdown-arrow {
    transform: translateX(25%) rotate(0deg);
  }

  &__links {
    flex-direction: column;
    transform: translate(1.563rem, 0.625rem);
    width: 100%;
    display: none;

    a {
      height: 100%;
      width: 100%;
      margin: 0.625rem 0;

      a,
      a:link,
      a:visited,
      a:hover,
      a:active {
        padding: 0.313rem 0;
        width: 100%;
        text-decoration: none;
        color: colors.$amway_black;
        display: flex;
        flex-direction: column;
        justify-content: center;
      }
    }
  }

  &__checkbox[type="checkbox"]:checked ~ &__links {
    display: flex;
  }
}
