@use "../../common/scss/colors";
@use "../../common/scss/font";
@use "../../common/scss/mixins";

.footer {
  background: colors.$very_light_gray;
  color: colors.$amway_black;
  padding: 2.625rem 0.625rem;
  text-align: center;
  font-size: font.$size--medium;
  font-weight: font.$weight--regular;

  &__divider {
    width: 0.0625rem;
    background: black;
    height: 1rem;
    display: flex;
    margin: 0 0.875rem;
  }

  &__fcy-text {
    padding-bottom: 0.5rem;
  }

  &__footer-links {
    padding-bottom: 1.625rem;
    display: flex;
    justify-content: center;

    a {
      padding: 0.125rem 0;
      font-size: font.$size--standard;
      font-weight: font.$weight--bold;
      border-bottom: 0.125rem solid colors.$amway_black;
      color: colors.$amway_black;
      font-family: var(--font-stack-source);
      text-decoration: none;
    }

    a:hover {
      color: colors.$blue;
      border-color: colors.$blue;
    }

    span {
      align-items: center;
      display: flex;
    }
  }
}

@include mixins.sm_l {
  .footer {
    font-size: font.$size--small;

    &__footer-links {
      a {
        font-size: font.$size--small;
      }
    }

    &__divider {
      height: 0.75rem;
      margin: 0 0.438rem;
    }
  }
}
