@use "../../common/scss/font";
@use "../../common/scss/colors";
@use "../../common/scss/mixins";

.smart-next-steps {
  flex-direction: row;
  display: flex;
  font-weight: font.$weight--bold;
  min-width: 0;

  @include mixins.md_l {
    flex-direction: column;
    align-self: flex-start !important;
  }

  a,
  a:link,
  a:visited,
  a:active {
    min-width: 0;
    color: colors.$amway_black;
    text-decoration: none;
    border: none;
    cursor: pointer;

    @include mixins.md_l {
      text-decoration: none;
      color: colors.$amway_black;
      border: none;
    }
  }

  a:hover {
    @include mixins.over_md_l {
      border-bottom: 0.125rem solid colors.$blue;
      color: colors.$blue;
    }
  }

  &__link {
    display: flex;
    flex-shrink: 0;
    min-width: 0;
    margin-left: 1.75rem;
  }

  &__link-arrow {
    display: none;

    @include mixins.md_l {
      display: flex;
      margin-left: 0.938rem;
    }
  }

  &__link-title {
    text-overflow: ellipsis;
    min-width: 1.75rem;
    max-width: 13.125rem;
    overflow: hidden;
    white-space: nowrap;

    @include mixins.md_l {
      padding: 0.313rem 0;
      margin: 0.313rem 0;
      max-width: 15.625rem;
    }
  }

  .hidden {
    display: none;
  }
}
