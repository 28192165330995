@use "../../scss/colors";
@use "../../scss/font";
@use "../../scss/mixins";
@use "../../scss/layers";

.leaf-selector {
  &__dropdown-menu {
    position: relative !important;
    display: inline-block;
    width: 100%;
    transform: none !important;
    z-index: layers.$content-five;
  }

  &__dropdown-toggle {
    width: 100%;
    background-color: colors.$white;
    border: 0.125rem solid colors.$black;
    padding: 0.188rem 0.75rem;
    height: 2.25rem;
    cursor: pointer;
    border-radius: 0.75rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 0.25rem;
    font-weight: font.$weight--medium;
    font-size: font.$size--medium;
    color: colors.$dark_grey_on_white;

    &--open {
      border: 0.125rem solid colors.$blue;
      color: colors.$black;
    }
  }

  &__dropdown-options {
    position: absolute;
    width: 100%;
    list-style: none;
    background-color: colors.$white;
    border: 0.125rem solid colors.$blue;
    z-index: layers.$content;
    border-radius: 0.75rem;
    max-height: 31.25rem;
    overflow-y: auto;
  }

  &__dropdown-options li {
    padding: 0.5rem 0.75rem;
    cursor: pointer;
    font-size: font.$size--medium;
    font-weight: font.$weight--medium;
  }

  &__dropdown-options li:hover {
    background-color: colors.$amway_white;
  }

  &__dropdown-options li.selected {
    background-color: colors.$amway_white;
    color: colors.$blue;
    font-weight: font.$weight--bold;
  }

  &__dropdown-options:first-child {
    border-radius: 0.75rem;
  }

  &__dropdown-options:last-child {
    border-radius: 0.75rem;
  }

  &__dropdown-icon {
    width: 0.7375rem;
    height: 1.063rem;
    display: flex;
    align-items: center;
    z-index: 10;
  }
}
