@use "../../scss/font";
@use "../../scss/colors";
@use "../../scss/mixins";

.leaf-tab-views {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0;
  gap: 1.5rem;
  margin-bottom: 2rem;

  &__header {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 0;
    margin-top: 0.5rem;
    gap: 1.5rem;
    list-style: none;
    cursor: pointer;
    font-weight: font.$weight--medium;
    font-size: font.$size--medium;
    color: colors.$blue;

    @include mixins.md {
      width: 70%;
    }

    @include mixins.sm_l {
      padding: 0.5rem 0 0;
    }
  }

  &__content {
    width: 100%;

    @include mixins.sm_l {
      padding: 0;
    }
  }

  &__item {
    display: none;

    &--active {
      display: contents;
    }
  }
}
