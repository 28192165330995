@use "../../scss/colors";

.icon-arrow-up {
  mask-image: url("./svg/arrow_up.svg");
  background-color: colors.$amway_black;
  mask-size: 0.875rem 0.438rem;
  height: 0.4375rem;
  width: 0.9375rem;
  display: block;
  filter: drop-shadow(0 0 2px black);

  &--white {
    mask-image: url("./svg/arrow_up.svg");
    background-color: colors.$white;
    background-repeat: no-repeat;
    display: inline-block;
    mask-size: 0.875rem 0.438rem;
    height: 0.4375rem;
    width: 0.9375rem;
  }

  &--blue {
    mask-image: url("./svg/arrow_up.svg");
    background-color: colors.$blue;
    background-repeat: no-repeat;
    display: flex;
    mask-size: 0.875rem 0.438rem;
    height: 0.4375rem;
    width: 0.9375rem;
  }

  &--thick {
    mask-image: url("./svg/thick_arrow_up.svg");
    background-repeat: no-repeat;
    display: flex;
    mask-size: 0.875rem 0.438rem;
    height: 0.4375rem;
    width: 0.9375rem;
  }

  &--md {
    mask-size: 1.125rem 0.6875rem;
    height: 0.6875rem;
    width: 1.125rem;
  }

  &--lg {
    height: 0.875rem;
    width: 1.875rem;
    mask-size: 1.875rem 0.875rem;
  }
}
