.icon-filter {
  display: block;
  height: 1.1875rem;
  width: 1.125rem;
  background: url("./svg/filter.svg");
  cursor: pointer;

  &__disabled {
    background: url("./svg/filterDisabled.svg");
    cursor: auto;
  }
}
