@use "../../../scss/font";
@use "../../../scss/colors";

.leaf-abo-phone-number {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 1rem;
  font-size: font.$size--medium;
  gap: 1rem;

  &__label {
    font-weight: font.$weight--medium;
  }

  &__primary {
    font-weight: font.$weight--regular;
    line-height: font.$size--big;
    color: colors.$dark_grey_on_white;
  }

  &__icon {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 0.5rem;

    &--copy {
      background: none;
      border: none;
    }
  }
}
