@use "../../../../scss/font";
@use "../../../../scss/colors";

.tag-primary {
  padding: 0.188rem 0.5rem;
  font-weight: font.$weight--medium;
  font-size: font.$size--medium;
  background: colors.$blue;
  border-radius: 0.25rem;
  line-height: font.$size--big;
  color: colors.$white;
}
