@use "../../../common/scss/font";
@use "../../../common/scss/mixins";

.feedback__header__title {
  height: 32px;
  font-family: font.$family--header;
  font-style: normal;
  font-weight: font.$weight--bold;
  font-size: font.$size--large_m;
  display: flex;
  align-items: center;
  padding: 24px 0 24px 16px;

  @include mixins.sm_l {
    padding: 24px 16px 16px;
    gap: 10px;
  }
}

#frame,
#iframeWrapper {
  height: 45rem;
  width: 100%;
  border: 10px;
}
