@use "../../scss/colors";

.icon-export-pdf {
  mask-image: url("./svg/exportPdf.svg");
  mask-repeat: no-repeat;
  background-color: black;
  display: block;
  height: 1.125rem;
  width: 1.125rem;
  scale: 1.3333;
  cursor: pointer;
}
