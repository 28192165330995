@use "../../../scss/colors";

.circleLoader {
  background: colors.$white;
  background: radial-gradient(
    circle,
    colors.$light_gray 0.19%,
    rgb(228 228 228 / 82.8%) 21.92%,
    rgb(228 228 228 / 0%) 100.19%
  );
  border-radius: 50%;
  animation: gradient 2s ease infinite;
  background-size: 250% 400%;
  padding-top: 100%;
}

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }

  50% {
    background-position: 100% 50%;
  }

  100% {
    background-position: 0% 50%;
  }
}
