@use "../../../common/scss/colors";
@use "../../../common/scss/font";
@use "../../../common/scss/mixins";

.BGTRequirementDonuts {
  &__wrapper {
    &--vertical {
      display: flex;
      flex-flow: nowrap;
      gap: 1rem;
    }

    &--horizontal {
      display: flex;
      flex-direction: column;
      gap: 0.25rem;
    }
  }

  &__labelMoreThanThree {
    max-width: auto;

    @media (width >= 1025px) and (width <= 1200px) {
      max-width: 2.5rem;

      .verticalSegmentsCircle {
        min-width: 2rem;
        max-width: 100%;
      }

      .LeafMiniSegmentedCircle__label {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 100%;
        min-width: 2rem;
      }
    }

    &--long {
      max-width: min(25%, 4rem);

      .verticalSegmentsCircle {
        min-width: 2rem;
        max-width: max(25%, 4rem);
      }

      @media (width >= 1025px) and (width <= 1200px) {
        max-width: 2.5rem;

        .verticalSegmentsCircle {
          max-width: 2.5rem;
        }
      }

      .LeafMiniSegmentedCircle__label {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 100%;
        min-width: 2rem;
      }
    }
  }

  &__label {
    &--long {
      .LeafMiniSegmentedCircle__label {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 100%;
        min-width: 2rem;
      }
    }
  }

  &__horizontal {
    display: flex;
    flex: 1;
    width: 100%;
    flex-direction: row;
    column-gap: 0.5rem;
    align-items: center;
  }

  &__req-info {
    display: flex;
    flex: 1;
    justify-content: space-between;
    column-gap: 0.5rem;
  }

  &__progress {
    display: flex;
    align-items: center;
    font-size: font.$size--standard;
  }

  &__req-met {
    color: colors.$light_green;
  }

  &__req-not-met {
    color: colors.$data_viz_orange;
  }
}
