@use "../../../common/scss/font";
@use "../../../common/scss/colors";

.account-selector {
  &__title {
    font-size: font.$size--large_l;
    font-weight: font.$weight--bold;
    padding: 1.5rem 0 1.5rem 3rem;
  }

  &__container {
    margin: 0 3rem;
  }

  &__header {
    margin: 2rem 1rem;
    font-family: font.$family--primary;
    font-style: normal;
    font-weight: font.$weight--medium;
    font-size: font.$size--large;
    line-height: 1.125rem;
    color: colors.$dark_grey_on_white;
    margin-bottom: 0.25rem;
  }
}
