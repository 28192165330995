@use "../../../common/scss/colors";
@use "../../../common/scss/font";
@use "../../../common/scss/mixins";

.Mpd {
  width: 100%;
  padding: 0;
  display: flex;

  &__container,
  &__container-split {
    display: flex;
    flex-direction: column;
    width: 100%;

    @include mixins.more_than_desktop_inclusive {
      flex: 1;
      align-items: flex-start;
    }
  }

  &__container-split {
    padding: 1rem 0;
    margin-top: -1rem;

    @include mixins.more_than_desktop_inclusive {
      flex-direction: row;
    }

    @include mixins.tablet_only {
      overflow-x: hidden;
    }

    @include mixins.sm_xl {
      overflow: hidden;
    }
  }

  &__section {
    padding: 0 1rem;
    margin: 0.5rem 0;
    width: 100%;
    flex: 1;

    &-title {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 1rem;

      button {
        border: none;
        background-color: colors.$white;

        :hover {
          cursor: pointer;
        }
      }

      h2 {
        padding: 0;
        margin: 0;
        font-size: 1.125rem;

        @include mixins.more_than_desktop_inclusive {
          font-size: 1.5rem;
        }
      }
    }

    .leaf-tile--no-border {
      padding-right: 0;
      padding-left: 0;
    }
  }
}
