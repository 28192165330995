@use "../../scss/colors";

%leafTileTitleLink {
  font-size: 10;
  display: flex;
  align-items: center;
  padding: 0.3125rem 0;
}

.leafTileTitleLink {
  @extend %leafTileTitleLink;

  h3 {
    color: colors.$blue;
    padding-right: 0.3125rem;
    font-size: 1rem;
  }

  .icon-wrapper {
    .icon-arrow-link--blue {
      padding: 0;
      cursor: pointer;
    }
  }

  &--disabled-link {
    @extend %leafTileTitleLink;

    .icon-wrapper {
      .icon-arrow-link--gray {
        cursor: default;
      }
    }

    h3 {
      color: colors.$dark_grey_on_white;
    }
  }
}
