@use "../../scss/mixins";
@use "../../scss/font";
@use "../../scss/colors";

.leaf-line-chart {
  &__container {
    height: 100%;
    background-color: colors.$white;

    svg {
      overflow: visible;

      g > g > text {
        background-color: colors.$white !important;
      }
    }
  }

  &__loader {
    padding-top: 1rem;
  }

  &__no-data {
    font-size: font.$size--huge;
    align-self: center;
    padding-top: 1.5rem;
  }
}
