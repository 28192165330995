@import "https://fonts.googleapis.com/css?family=Source+Code+Pro|Source+Sans+Pro:300,300i,400,400i,700,700i&display=swap";

@at-root {
  // GT Walsheim Pro Amway
  @font-face {
    font-family: GTWalsheimPro;
    src: url("../fonts/gt-walsheim/GT-Walsheim-Pro-Regular.woff2") format("woff2"),
      url("../fonts/gt-walsheim/GT-Walsheim-Pro-Regular.woff") format("woff");
    font-weight: 400;
  }

  @font-face {
    font-family: GTWalsheimPro;
    src: url("../fonts/gt-walsheim/GT-Walsheim-Pro-Medium.woff2") format("woff2"),
      url("../fonts/gt-walsheim/GT-Walsheim-Pro-Medium.woff") format("woff");
    font-weight: 600;
  }

  @font-face {
    font-family: GTWalsheimPro;
    src: url("../fonts/gt-walsheim/GT-Walsheim-Pro-Bold.woff2") format("woff2"),
      url("../fonts/gt-walsheim/GT-Walsheim-Pro-Bold.woff") format("woff");
    font-weight: 700;
  }
}
