@use "../../../../common/scss/mixins";

.annualIncentiveTrackingTile {
  &__container {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }

  &__groupGrowthTiles {
    display: flex;
    flex-direction: row;
    gap: 1rem;

    @include mixins.sm_xl {
      flex-direction: column;
    }
  }

  &__tile {
    flex-basis: 50%;

    @include mixins.sm_xl {
      flex-basis: 100%;
    }

    &--fullwidth {
      flex-basis: 100% !important;
    }

    > .leaf-tile {
      padding: 0.75rem 1rem;
    }
  }

  &__singleTile {
    gap: 0;
  }
}
