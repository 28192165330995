@use "../../../common/scss/colors";
@use "../../../common/scss/layers";

%drawerBase {
  transition: all 0.5s ease-out;
  border-radius: 2rem 2rem 0 0;
  min-height: 24.3rem;
  max-height: 40rem;
  background-color: colors.$white;
  position: fixed;
  bottom: 0;
  right: 0;
  left: 0;
  will-change: transform;
  z-index: layers.$panel;
  color: colors.$amway_black;
}

.mobile-period-selector {
  &__drawer {
    transform: translateY(100%);

    @extend %drawerBase;

    &--open {
      transform: translateY(0);

      @extend %drawerBase;

      transition: height 0.3s ease;
    }
  }

  &__handle {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 3.5rem;
    background: #fafafa;
    border-radius: 2rem 2rem 0 0;
  }
}
