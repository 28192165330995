%icon-chain {
  background: url("./svg/icon_chain.svg") center center no-repeat;
  display: block;
}

.icon-chain {
  @extend %icon-chain;

  &--lg {
    @extend %icon-chain;

    background-size: 6rem 6rem;
    height: 6rem;
    width: 6rem;
  }

  &--small {
    @extend %icon-chain;

    background-size: 2rem 2rem;
    height: 2rem;
    width: 2rem;
  }
}
