.regulation-info-banner {
  .leafBannerToast {
    display: flex;
    flex-direction: row;
    padding: 0.75rem;
  }
}

.regulationInfoBanner {
  line-height: 1.5rem;

  &__title {
    display: flex;
    font-weight: 700;
  }

  &__icon {
    padding-right: 0.375rem;
  }

  &__message {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
}
