@use "../../common/scss/colors";
@use "../../common/scss/font";

.busSelector {
  p {
    color: colors.$dark_grey;
  }

  &__aboInfo {
    padding: 0 !important;
    margin: 0 !important;

    h2 {
      font-size: font.$size--standard;
      font-weight: font.$weight--thin;
    }
  }

  &__desktop > div > button {
    padding: 0;
  }

  &__mobile {
    max-width: 75vw;

    h1,
    h2 {
      white-space: nowrap;
      text-wrap: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }

    h1 {
      font-size: 1.54rem;
    }

    h2 {
      font-size: 1.25rem;
      font-weight: font.$weight--thin;
    }
  }

  &__subheading {
    display: flex;
    margin: 0;
    padding: 0;
    align-items: center;
    font-weight: font.$weight--thin;

    * {
      padding: 0 0.15rem;
    }

    &--no-pointer {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      column-gap: 0.35rem;
    }
  }

  &__subheading:hover,
  &__subheading > *:hover {
    cursor: pointer;
  }

  .rectangle-loader {
    margin: 0.5rem 0.75rem 0 !important;
  }
}
