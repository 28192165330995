@use "../../../common/scss/colors";
@use "../../../common/scss/mixins";
@use "../../../common/scss/animations";
@use "../../../common/scss/layers";

.hamburger-menu {
  a,
  a:link,
  a:visited,
  a:hover,
  a:active {
    text-decoration: none;
    color: colors.$amway_black;
    cursor: pointer;
    align-items: center;
  }

  &__link {
    display: flex;
    padding: 0.313rem 0;
    margin: 0.313rem 0;
    margin-left: 0.938rem;
    align-items: center;

    #mobile-view-log-out {
      width: 100%;
    }

    span {
      margin-left: 0.938rem;
    }
  }

  &__nav-container {
    width: 100%;
    transform: translateX(-100%);
    position: absolute;
    inset: 0;
    z-index: layers.$nav;
    color: colors.$amway_black;
    padding-top: 1.25rem;
    display: flex;
    flex-direction: column;
    background: colors.$white;
    overflow: scroll;
  }

  &__section {
    display: flex;
    flex-direction: column;
    width: 93%;
    align-self: center;
    border-bottom: 0.0625rem solid colors.$light_gray;
    padding: 1.25rem 0;

    & > &-container {
      display: flex;
      flex-direction: column;
      width: 100%;
    }

    .hamburger-menu__menu-icon {
      align-self: flex-start;
      margin: 0.625rem 0;
    }

    &__footer {
      width: 93%;
      margin-top: auto;
      margin-left: -0.938rem;
      padding: 1.25em 0;
    }

    h1 {
      font-size: 2rem;
    }

    h2 {
      font-size: 1.313rem;
    }

    p {
      color: colors.$dark_grey;
    }
  }

  &__link:first-of-type {
    margin-top: 0.938rem;
  }

  &__hamburger-icon {
    position: relative;
    z-index: layers.$nav-content;

    // Hamburger styling
    & > *,
    *::before,
    *::after {
      height: 0.125rem;
      width: 1.875rem;
      background-color: colors.$amway_black;
      margin: 0.5625rem 0;
    }
  }

  &__dropdown {
    width: 100%;
  }

  &__dropdown:last-child {
    margin-bottom: -1.25rem;
  }

  &__hamburger-checkbox[type="checkbox"] {
    z-index: layers.$nav-button;
    position: absolute;
    min-height: 1.875rem;
    min-width: 1.875rem;
    opacity: 0;
  }

  &__hamburger-checkbox[type="checkbox"]:checked,
  &__hamburger-checkbox[type="checkbox"]:checked ~ &__hamburger-icon {
    transform: translateX(90vw);

    @include mixins.sm_l {
      transform: translateX(80vw);
    }
  }

  &__hamburger-checkbox[type="checkbox"]:checked ~ &__hamburger-icon > div {
    background-color: colors.$amway_black;
  }

  &__hamburger-checkbox[type="checkbox"]:checked ~ &__hamburger-icon > &__hamburger-icon-top {
    transform: translate(0, 0.6875rem) rotate(-45deg);
  }

  &__hamburger-checkbox[type="checkbox"]:checked ~ &__hamburger-icon > &__hamburger-icon-middle {
    transform: rotate(45deg);
  }

  &__hamburger-checkbox[type="checkbox"]:checked ~ &__hamburger-icon > &__hamburger-icon-bottom {
    transform: translate(0, -0.6875rem) rotate(45deg);
  }

  &__hamburger-checkbox[type="checkbox"]:checked ~ &__nav-container {
    transform: translateX(0%);
  }

  &__abo-info {
    max-width: 75vw;

    * {
      white-space: nowrap;
      text-wrap: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }

    h1 {
      font-size: 1.54rem;
    }
  }

  &__section:first-of-type > &__abo-info {
    align-self: flex-start;
  }

  &__section:first-of-type > .hamburger-menu__menu-icon {
    transform: translate(-1.25rem, 0.625rem);
  }

  &__section:last-of-type {
    border: none;
  }

  &__smart-next-steps {
    display: flex;
    flex-direction: column;
    width: 100%;

    .smart-next-steps__link {
      margin-left: 0;
    }
  }

  &__performance-incentives,
  &__academy {
    width: 100%;
    align-self: flex-start;

    .leaf-dropdown__dropdown-title {
      left: 2%;

      @include mixins.sm_l {
        left: 3%;
      }
    }

    .leaf-dropdown__dropdown-arrow {
      left: 95%;

      @include mixins.sm_l {
        left: 92%;
      }
    }
  }
}

.hidden {
  display: none;
}
