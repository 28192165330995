@use "../../scss/mixins";

.Icon-General {
  padding-top: 0.5rem;

  svg {
    width: 18.645rem;
    height: 16rem;

    @include mixins.md_s {
      width: 12.236rem !important;
      height: 10.5rem !important;
    }
  }
}
