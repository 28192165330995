.CopyToClipboard {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: rgb(107 114 128 / 5%);
  border-radius: 0.35rem;
  border: 1px solid #d1d5db85;
  height: 2.25rem;
  padding: 0.5rem;
  margin-top: 1rem;
  gap: 0.5rem;

  &__copy-text {
    font-size: 0.875rem;
    color: rgb(107 114 128 / 90%);
    max-width: 18rem;
    overflow: auto;
    white-space: nowrap;
    padding: 0.5rem;
    scrollbar-width: none;
    scrollbar-color: #888 transparent;
  }

  &__divider {
    color: rgb(128 128 128 / 90%);
    font-size: 1rem;
    margin-left: 0.3rem;
  }

  &__button {
    background: none;
    border: none;
    cursor: pointer;

    &:hover {
      background: rgb(128 128 128 / 10%);
      border-radius: 5rem;
    }
  }

  &__icon {
    height: 1.75rem;
    width: 1.65rem;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .leaf-tooltip__tooltip {
    width: max-content;
  }
}
