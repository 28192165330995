@use "../../../../../common/scss/font";
@use "../../../../../common/scss/mixins";

.ss-export-notify-container {
  .Toastify {
    &__toast-container {
      width: fit-content;

      @include mixins.sm_l {
        width: 100%;
        max-width: 96%;
      }

      &--top-center {
        @include mixins.sm_l {
          margin-left: 0.4rem;
        }
      }
    }

    &__toast {
      width: fit-content;
      height: auto;
    }
  }
}

.ss-export-toast {
  display: flex;

  &__icon {
    margin-top: 0.2rem;

    @include mixins.less_than_desktop_inclusive {
      margin-top: 0;
    }
  }

  &__text {
    margin-left: 0.5rem;
  }

  &__content {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    width: 100%;
    justify-content: space-between;
  }

  &__title {
    font-size: font.$size--standard;
    font-weight: font.$weight--bold;
    line-height: 1.5rem;

    @include mixins.less_than_desktop_inclusive {
      font-size: font.$size--medium;
      line-height: 1.125rem;
    }
  }

  &__subtitle {
    font-size: font.$size--standard;
    line-height: 1.5rem;

    @include mixins.less_than_desktop_inclusive {
      font-size: font.$size--medium;
      line-height: 1.125rem;
    }
  }

  &__download-link {
    @include mixins.less_than_desktop_inclusive {
      font-size: font.$size--medium;
    }

    text-wrap: nowrap;
  }
}
