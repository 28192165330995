@use "../../../common/scss/colors";
@use "../../../common/scss/font";
@use "../../../common/scss/layers";

$itemHeight: 2.5rem;

.wheel-column {
  padding: 2 * $itemHeight 0;
  flex-direction: column;
  height: calc($itemHeight * 5);
  overflow: hidden;
  overflow-y: scroll;
  scroll-snap-type: y mandatory;
  scroll-margin: $itemHeight 0;
  scroll-behavior: smooth;
  z-index: layers.$content;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  border: none;
  width: 100%;
  background: transparent;
  font-size: font.$size--big;
  line-height: $itemHeight;
  color: colors.$disabled;
  mask-image: linear-gradient(0deg, transparent, transparent 1%, #fff 20%, white 80%, transparent 99%, transparent);

  &__item {
    border: none;
    display: flex;
    height: $itemHeight;
    line-height: $itemHeight;
    justify-content: center;
    align-items: center;
    background: transparent;
    scroll-snap-align: center;
    gap: 1.5625rem;

    &--active {
      border: none;
      display: flex;
      height: $itemHeight;
      line-height: $itemHeight;
      justify-content: center;
      align-items: center;
      background: transparent;
      scroll-snap-align: center;
      color: colors.$blue;
      font-weight: font.$weight--semibold;
      gap: 1.5625rem;
    }
  }

  &__left-col,
  &__right-col {
    width: 50%;
  }

  &__left-col {
    text-align: right;
  }

  &__right-col {
    text-align: left;
  }
}

/* Chrome and Safari */
.wheel-column::-webkit-scrollbar {
  display: none;
}

.wheel-column:focus {
  border: none;
  outline: 0;
}
