@use "../../../../common/scss/colors";
@use "../../../../common/scss/font";
@use "../../../../common/scss/mixins";

.MpdInfoDrawer {
  &__wrapper {
    background: colors.$white;
    padding: 1rem 0.5rem;
    padding-bottom: 0.2rem;

    @include mixins.less_than_tablet {
      box-shadow: none;
    }
  }

  &__iconCircle {
    display: inline-flex;
    margin-left: 0.3125rem;
    height: 1.125rem;
    vertical-align: middle;

    svg {
      height: 0.875rem;
      width: 0.875rem;
    }
  }

  h2 {
    font-size: font.$size--standard;
  }

  &__title {
    font-style: normal;
    font-weight: font.$weight--bold;
    font-size: font.$size--standard;
  }

  &__desc {
    font-style: normal;
    font-weight: font.$weight--regular;
    font-size: font.$size--standard;
    margin-top: 0.5rem;
    margin-bottom: 1.5rem;
    line-height: font.$size--large;
  }
}

.monthly-performance-info-modal {
  .leaf-panel__header {
    border-bottom: none;
    height: 9%;
  }

  .leaf-panel__header-title {
    font-weight: 500;
    font-size: 1.3rem;
  }
}
