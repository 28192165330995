@use "../../../../../common/scss/mixins";
@use "../../../../../common/scss/colors";
@use "../../../../../common/scss/font";

.HAT {
  &__title-link {
    padding-bottom: 0.3125rem;
  }

  &__spacing {
    padding: 0.75rem 0;

    @include mixins.sm_xl {
      padding: 0;
    }
  }

  &__content-container {
    padding-top: 0.3125rem;

    @include mixins.lg_s {
      font-size: font.$size--medium;
    }

    display: grid;
    gap: 0.5rem;
    grid-template-columns: repeat(2, 1fr);

    &--space {
      @include mixins.md_s {
        display: none;
      }
    }
  }

  &__row-label {
    padding: 0.3125rem 0;
    color: colors.$dark_grey_on_white;
  }

  &__row-value {
    padding: 0.3125rem 0;
    display: flex;
    text-align: right;
    color: colors.$amway_black;
    font-weight: bold;
    justify-content: flex-end;

    .awardProgressBadge__title {
      height: fit-content;
      text-wrap: pretty !important;
      text-align: center;

      @include mixins.lg_s {
        font-size: font.$size--medium !important;
      }
    }
  }
}
