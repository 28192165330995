@use "../../../../common/scss/font";
@use "../../../../common/scss/mixins";

.Fpp {
  &__tiles-section {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 0.5rem;
    padding: 1rem 0;

    * > {
      height: auto;
    }

    @include mixins.md_s {
      flex-direction: column;
    }
  }

  &__error-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
  }

  .leaf-tile {
    display: flex;
    flex-direction: column;
    align-items: center;
    min-width: 1rem;
    height: auto;
    width: 33vw;

    @include mixins.md_s {
      width: 100%;
    }
  }

  &__description {
    font-size: font.$size--big;
    margin: 0 0 0.25rem;

    @include mixins.md_s {
      font-size: font.$size--medium;
    }
  }

  &__sub-description {
    display: flex;
    flex-direction: column;
    gap: 0.6rem;
  }

  &__sub-description p {
    font-size: font.$size--medium;
  }

  &__tooltip {
    padding: 0.5rem 0;
    line-height: font.$size--large;
  }

  &__tooltip-title {
    font-weight: font.$weight--bold;
  }

  &__bold-text {
    font-weight: font.$weight--bold;
  }
}
