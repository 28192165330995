.leafBackdrop {
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: rgb(112 112 112 / 70%);
  top: 0;
  right: 0;

  &--invisible {
    background-color: transparent !important;
  }
}
