@use "../../scss/colors";
@use "../../scss/layers";

%input-container {
  border: 1px solid colors.$amway_black;
  border-radius: 0.75rem;
  padding: 0 0.5rem 0 0.75rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.searchableDropdown {
  word-spacing: normal;
  letter-spacing: normal;
  display: flex;
  flex-direction: column;
  position: relative;
  bottom: 0.7rem;
  margin-right: 0.75rem;

  &__input-container {
    @extend %input-container;

    &--active {
      @extend %input-container;

      border-color: colors.$dark_shade_cyan;
    }
  }

  &__input {
    width: 100%;
    border: none !important;
    box-shadow: none !important;
    background: transparent;
    height: 100%;
  }

  &__input::placeholder {
    color: colors.$medium_gray;
  }

  &__toggle-btn {
    background-color: transparent;
    outline: none;
    cursor: pointer;
    width: 2rem;
    height: 1.7rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 0.313rem;
  }

  &__toggle-btn:hover {
    background-color: colors.$light_gray_bg;
  }

  &__toggle-btn:focus {
    border: 0.125rem solid colors.$blue;
  }

  &__options-list-wrapper {
    position: absolute;
    top: calc(100% + 0.313rem);
    left: 0;
    z-index: layers.$dropdown;
    padding: 0.5rem 0.25rem;
    margin: 0;
    background-color: colors.$white;
    border: 0.063rem solid colors.$light_background;
    border-radius: 0.5rem;
    box-shadow: 0 0.125rem 0.25rem rgb(0 0 0 / 10%);
    max-height: 12.5rem;
    width: 100%;
    height: auto;
  }

  &__options-list {
    overflow: hidden auto;
    list-style: none;
    max-height: 12.5rem;
    scroll-behavior: smooth;
    scroll-snap-align: top;
    scroll-snap-type: y mandatory;
  }

  &__option {
    padding: 0.5rem;
    cursor: pointer;
  }

  &__option:hover {
    background-color: colors.$light_gray_bg;
  }
}
