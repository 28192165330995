@use "../../../../../common/scss/colors";

.perfBonus_container {
  display: flex;
  align-items: center;
  margin: 0.5rem 0 1.5rem;

  &__icon {
    margin-right: 0.3rem;
  }

  &__met {
    color: colors.$light_green;
  }

  &__not-met {
    color: colors.$blue;
  }
}
