.icon-browsers {
  %browsers {
    display: flex;
    height: 100%;
    width: 100%;
  }

  &__chrome {
    @extend %browsers;

    background: url("./svg/chrome.svg") no-repeat;
    background-size: 2rem 2rem;
    width: 2rem;
    height: 2rem;
  }

  &__edge {
    @extend %browsers;

    background: url("./svg/edge.svg") no-repeat;
    background-size: 2rem 2rem;
    width: 2rem;
    height: 2rem;
  }

  &__firefox {
    @extend %browsers;

    background: url("./svg/firefox.svg") no-repeat;
    background-size: 2rem 2rem;
    width: 2rem;
    height: 2rem;
  }

  &__safari {
    @extend %browsers;

    background: url("./svg/safari.svg") no-repeat;
    background-size: 2rem 2rem;
    width: 2rem;
    height: 2rem;
  }
}
