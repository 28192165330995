@use "../../scss/colors";

%icon-arrow-link {
  display: block;
  height: 1.25rem;
  width: 1.25rem;
  mask-image: url("./svg/arrow_link.svg");
  background-repeat: no-repeat;
}

.icon-arrow-link {
  @extend %icon-arrow-link;

  &--black {
    @extend %icon-arrow-link;

    background-color: colors.$amway_black;
  }

  &--white {
    @extend %icon-arrow-link;

    background-color: colors.$white;
  }

  &--gray {
    @extend %icon-arrow-link;

    background-color: colors.$dark_grey_on_white;
  }

  &--blue {
    @extend %icon-arrow-link;

    background-color: colors.$blue;
  }
}
