@use "../../scss/colors";
@use "../../scss/font";
@use "../../scss/mixins";

.bonus-footer-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: colors.$dark_grey_on_white;
  font-style: italic;
  font-size: font.$size--medium;
  padding: 0 2.5rem;
  margin: 1.5rem 0 3.5rem;

  @include mixins.less_than_desktop_inclusive {
    padding: 0 1.5rem;
  }
}

.short-date {
  color: colors.$dark_grey_on_white;
  text-align: center;
  padding: 1.5rem 0;

  @include mixins.over_md_l {
    display: none;
  }
}

#bonus-info-footer-link {
  border-bottom: 0.12rem solid colors.$link_purple;
  color: colors.$link_purple;
}

#bonus-info-footer-link:active {
  border-bottom: 0.12rem solid colors.$bright_red;
  color: colors.$bright_red;
}

.market-disclaimer {
  font-weight: font.$weight--semibold;
}
