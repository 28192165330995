@use "../../../../../common/scss/mixins";
@use "../../../../../common/scss/colors";
@use "../../../../../common/scss/font";

.TrackingTile {
  &__title-link {
    padding-bottom: 0.3125rem;
  }

  &__spacing {
    padding: 0.625rem 0;

    @include mixins.sm_xl {
      padding: 0;
    }
  }

  &__link {
    font-size: font.$size--standard;
    color: colors.$blue;
    font-weight: font.$weight--bold;
    cursor: pointer;
    background-color: colors.$white;
    text-decoration: underline;
    border: 0;
    border-color: colors.$blue;
    line-height: font.$size--large;
    text-align: left;
    text-underline-offset: 0.3rem;
    text-decoration-thickness: 2px;

    @include mixins.lg_s {
      font-size: font.$size--medium;
    }
  }

  &__content-container {
    display: flex;
    flex-direction: column;
  }

  &__mobileDivider {
    @include mixins.larger_than_mobile {
      display: none;
    }
  }

  &__content-row {
    padding-top: 0.3125rem;
    display: flex;
    justify-content: space-between;
    gap: 2%;
    margin: 0.15rem 0;

    @include mixins.under_md_ms {
      margin-top: 0.15rem;
    }

    p {
      color: colors.$dark_grey_on_white;
    }

    &--bold {
      color: colors.$amway_black;
      font-weight: bold;
      text-align: right;

      @include mixins.larger_than_mobile {
        margin: 0.4rem 0;
        max-width: 49%;
      }
    }

    span {
      @include mixins.sm_xl {
        margin: 0.55rem 0;
        text-align: left;
      }

      .awardProgressBadge__title {
        height: fit-content;
        text-wrap: pretty !important;

        @include mixins.lg_s {
          font-size: font.$size--medium !important;
        }
      }
    }

    @include mixins.sm_xl {
      flex-direction: column;
    }
  }

  &__space {
    padding: 0.625rem 0;

    @include mixins.md_s {
      display: none;
    }
  }
}
