@use "../../scss/font";
@use "../../scss/colors";

.leaf-miniseg-circle-container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.verticalSegmentsCircle {
  flex-direction: column;
  width: max-content;
  padding: 0.125rem 0;

  .LeafMiniSegmentedCircle {
    &__label {
      color: colors.$dark_grey_on_white;
      padding: 0.125rem 0 0;
      text-align: center;
    }
  }
}

.horizontalSegmentsCircle {
  flex-direction: row;
  justify-content: left;

  .LeafMiniSegmentedCircle {
    &__label {
      color: colors.$amway_black;
      padding-left: 0.75rem;
      font-size: font.$size--standard;
    }
  }
}

.LeafMiniSegmentedCircle {
  height: 2rem;
  width: 2rem;
  display: flex;
  justify-content: center;

  &__Loader {
    padding-top: 1.5rem;
    height: 2.1rem;
    width: 2.1rem;
  }

  .pf-v5-c-chart {
    pointer-events: none !important;

    svg {
      pointer-events: none !important;
    }
  }
}

.Circle__no-segments {
  .pf-v5-c-chart {
    svg > g > path:nth-child(2) {
      fill: #e4e4e4 !important;
    }
  }
}

.Circle__segments {
  .pf-v5-c-chart {
    svg > g > path:nth-child(even) {
      fill: #fff !important;
    }
  }
}
