@use "../../../scss/colors";
@use "../../../scss/layers";

.BottomDrawer {
  &__container {
    display: flex;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: layers.$dropdown;
  }

  &__clickyArea {
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  &__drawer {
    &--open {
      border-radius: 2rem 2rem 0 0;
      min-height: 24.3rem;
      max-height: 40rem;
      background-color: colors.$white;
      position: absolute;
      bottom: 0;
      right: 0;
      left: 0;
      will-change: transform;
      color: colors.$amway_black;
    }
  }

  &__handle {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 3.5rem;
    background: #fafafa;
    border-radius: 2rem 2rem 0 0;
    cursor: pointer;
  }
}
