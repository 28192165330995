@use "../../scss/colors";

.icon-alert {
  mask-image: url("./svg/alert.svg");
  mask-repeat: no-repeat;
  background-color: colors.$orange;
  display: block;
  height: 1.125rem;
  width: 1.125rem;
  scale: 1.3333;
}
