@use "../../scss/font";
@use "../../scss/colors";
@use "../../scss/mixins";

.nav {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0;
  gap: 1.5rem;

  &__list {
    list-style: none;
    cursor: pointer;
    font-family: font.$family--primary;
    font-weight: font.$weight--medium;
    font-style: normal;
    font-size: font.$size--standard;
    text-align: center;
    color: colors.$blue;
    line-height: 1.5rem;

    @include mixins.sm_l {
      font-size: font.$size--medium;
    }
  }

  &__link {
    text-decoration: none;
    line-height: 1.5rem;

    &--active {
      font-weight: font.$weight--bold;
      display: flex;
      flex-direction: column;
      text-decoration: underline 0.125rem !important;
      text-underline-offset: 0.375rem;
    }
  }
}
