@use "../../scss/colors";

%icon-arrow-dropdown {
  mask-image: url("./svg/arrow_dropdown.svg");
  mask-repeat: no-repeat;
  background-color: colors.$amway_black;
  background-size: 0.875rem 0.438rem;
  height: 0.4375rem;
  width: 0.9375rem;
  display: block;
}

.icon-arrow-dropdown {
  @extend %icon-arrow-dropdown;

  &--black {
    @extend %icon-arrow-dropdown;
  }

  &--white {
    @extend %icon-arrow-dropdown;

    mask-image: url("./svg/arrow_dropdown.svg");
    background-color: colors.$white;
  }

  &--blue {
    mask-image: url("./svg/arrow_dropdown.svg");
    background-color: colors.$blue;
  }

  &--thick {
    mask-image: url("./svg/thick_arrow_dropdown.svg");
  }

  &--lg {
    height: 0.875rem;
    width: 1.875rem;
    mask-size: 1.875rem 0.875rem;
  }
}
