.icon-amway {
  mask-image: url("./svg/amway.svg");
  background-repeat: no-repeat;
  cursor: pointer;
  display: block;
  height: 3.75rem;
  margin-top: 0.938rem;
  width: 8.125rem;

  &--black {
    mask-image: url("./svg/amway.svg");
    background-repeat: no-repeat;
    cursor: pointer;
    display: block;
    height: 3.75rem;
    margin-top: 0.938rem;
    width: 8.125rem;
    background-color: black;
  }
}
