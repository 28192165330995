@use "../../scss/colors";
@use "../../scss/font";
@use "../../scss/mixins";

.leaf-table {
  font-size: font.$size--medium;
  line-height: 1.125rem;
  background-color: none;
  color: colors.$amway_black;
  width: 100%;
  text-align: center;
  border-collapse: collapse;

  &__header-default {
    background: colors.$amway_black;
    border: solid 1px colors.$amway_black;
    color: colors.$white;
    font-weight: font.$weight--bold;
    font-size: font.$size--standard;
    padding: 1rem;

    &__hidden {
      display: none;
    }

    @include mixins.md {
      font-size: font.$size--medium;
      padding: 1rem 0.5rem;
    }
  }

  &__header-clear {
    background: white;
    font-weight: 700;
    font-size: 1rem;
    padding: 1rem;

    @include mixins.md {
      font-size: font.$size--medium;
      padding: 1rem 0.5rem;
      word-break: break-word;
    }
  }

  &__row {
    height: 3rem;
    font-size: font.$size--standard;

    @include mixins.md {
      font-size: font.$size--medium;
    }

    td {
      padding: 1rem 0.5rem;
    }
  }

  tr:nth-child(odd) {
    background: colors.$amway_white;
  }
}
