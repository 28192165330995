@use "../../../../common/scss/colors";
@use "../../../../common/scss/font";
@use "../../../../common/scss/mixins";

.abo-profile-body {
  &__abo-info {
    display: flex;
    flex-direction: column;
    background-color: colors.$very_light_gray;
    border: 0.063rem solid colors.$light_gray;
    padding: 1rem 0;

    &__header,
    &__primary-applicant,
    &__applicant-carousel {
      padding: 0 1rem;
    }
  }

  &__tabs {
    margin: 1rem;
  }
}
