@use "../../../common/scss/mixins";

.mbi-loader {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 1.5rem;
  width: 100%;

  &-header {
    width: 100%;
    display: flex;
    flex-flow: column;
  }

  &-top {
    width: 100%;
    display: flex;
    flex-flow: row wrap;
  }

  &-top-left,
  &-top-right {
    width: 49%;
    display: flex;
    flex-direction: column;
  }

  &-top-right {
    justify-content: space-between;
  }

  &-bottom {
    width: 100%;
    display: flex;
    flex-direction: column;
  }

  @include mixins.md {
    &-header,
    &-top,
    &-top-left,
    &-top-right {
      gap: 0;
      margin: 0;
      width: 100%;
    }
  }
}
