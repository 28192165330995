@use "../../../../../../common/scss/colors";
@use "../../../../../../common/scss/font";
@use "../../../../../../common/scss/mixins";

.key-dates-tab {
  &__container {
    display: flex;
    flex-direction: column;
  }

  &__content {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    padding-top: 1.5rem;

    @include mixins.md_l {
      gap: 0.5rem;
    }

    > :nth-child(n) {
      border-radius: 0.75rem;
      border: 0.0625rem solid colors.$light_gray;
      padding: 1rem;
    }
  }
}
