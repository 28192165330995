@use "../../../../../common/scss/font";

.abo-profile-header {
  &__abo-details {
    display: flex;
    flex-direction: column;
  }

  &__abo-name {
    font-weight: font.$weight--bold;
    font-size: font.$size--large;
  }

  &__abo-number {
    display: flex;
  }
}
