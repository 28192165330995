@use "../../scss/colors";

%iconBase {
  display: flex;
  flex: 1;
}

.icon-carrot {
  &__left {
    @extend %iconBase;

    transform: rotate(0deg);
  }

  &__right {
    @extend %iconBase;

    transform: rotate(180deg);
  }

  &__up {
    @extend %iconBase;

    transform: rotate(90deg);
  }

  &__down {
    @extend %iconBase;

    transform: rotate(270deg);
  }

  &--disabled > * {
    fill: colors.$disabled_gray !important;
  }

  &__white > * {
    fill: colors.$white;
  }

  &__black > * {
    fill: colors.$amway_black;
  }

  &__blue > * {
    fill: colors.$blue;
  }

  &__purple > * {
    fill: colors.$dark_purple_2;
  }
}
