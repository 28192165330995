@use "../../scss/colors";

.ErrorTile {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  width: 100%;
  cursor: pointer;

  * {
    cursor: pointer;
  }

  &__error-msg {
    margin: 1rem;
  }

  &__try-again-btn {
    display: flex;
    justify-content: center;
    border: none;
    background: none;
  }

  &__try-again-btn p {
    margin-left: 0.4rem;
    color: colors.$blue;
    font-size: 14px;
    font-weight: 700;
  }
}
