@use "../../scss/colors";
@use "../../scss/mixins";

.Toastify {
  &__toast-icon {
    display: none;
  }

  &__toast {
    padding: 0.563rem 0.625rem;
    border-radius: 0.75rem;
    border: 0.063rem solid colors.$green_outline;
    background-color: colors.$light_green_8;
    left: 50%;
    transform: translateX(-50%);
    color: colors.$light_green;
    box-shadow: none;
    width: 21.438rem;
    height: 2.625rem;

    &--success {
      border-color: colors.$green_outline;
      background-color: colors.$light_green_8;
      color: colors.$light_green;
    }

    &--error {
      border-color: colors.$outline_red;
      background-color: colors.$light_red;
      color: colors.$error_red;
    }

    &--info {
      border-color: colors.$dark_blue_5;
      background-color: colors.$light_blue_6;
      color: colors.$blue;
    }

    @include mixins.less_than_desktop_inclusive {
      width: 21.4375rem;
    }

    @include mixins.sm_l {
      margin-bottom: 1rem;
    }
  }

  &__toast-container--top-center {
    @include mixins.sm_l {
      top: 1em;
      margin-left: 0.2rem;
      margin-right: 0.2rem;
    }
  }

  @keyframes Toastify__bounceInRight {
    from {
      opacity: 0;
    }

    to {
      opacity: 1;
    }
  }

  @keyframes Toastify__bounceOutRight {
    from {
      opacity: 1;
    }

    to {
      opacity: 0;
    }
  }

  @keyframes Toastify__bounceInLeft {
    from {
      opacity: 0;
    }

    to {
      opacity: 1;
    }
  }

  @keyframes Toastify__bounceOutLeft {
    from {
      opacity: 1;
    }

    to {
      opacity: 0;
    }
  }

  @keyframes Toastify__bounceInUp {
    from {
      opacity: 0;
    }

    to {
      opacity: 1;
    }
  }

  @keyframes Toastify__bounceOutUp {
    from {
      opacity: 1;
    }

    to {
      opacity: 0;
    }
  }

  @keyframes Toastify__bounceInDown {
    from {
      opacity: 0;
    }

    to {
      opacity: 1;
    }
  }

  @keyframes Toastify__bounceOutDown {
    from {
      opacity: 1;
    }

    to {
      opacity: 0;
    }
  }
}

.custom-close-button {
  border: none;
  background-color: transparent;
  margin-bottom: 5vh;
}
