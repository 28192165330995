@use "../../../../common/scss/font";
@use "../../../../common/scss/colors";
@use "../../../../common/scss/mixins";

.InternationalBusinesses {
  &__container {
    display: grid;
    grid-gap: 1rem;
    grid-template-columns: 1fr;
    grid-template-rows: auto;

    @include mixins.from_tablet {
      grid-template-columns: repeat(2, 1fr);
    }

    @include mixins.more_than_desktop_inclusive {
      grid-template-columns: repeat(4, 1fr);
    }
  }

  &__drawer {
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.3s ease-out;

    &--show {
      max-height: 10000000rem;
    }
  }

  &__arrow-icon {
    border-radius: 0.25rem;
    background: colors.$very_light_gray;
    border: solid 0.0625rem colors.$light_gray;
    width: 2.5rem;
    height: 2.5rem;
    display: flex;
    place-content: center;
    place-items: center;
    cursor: pointer;

    @include mixins.more_than_desktop_inclusive {
      display: none;
    }

    &--up {
      cursor: pointer;
    }

    &--down {
      cursor: pointer;

      span {
        transform: rotateX(180deg);
      }
    }
  }

  &__heading {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 1rem;
    align-items: center;
  }
}
