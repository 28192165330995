@use "../../../../common/scss/mixins";
@use "../../../../common/scss/colors";
@use "../../../../common/scss/font";

.ggTrackingTile {
  &__content-container {
    padding-top: 0.625rem;
    gap: 0.5rem;
    font-size: 1rem;
    display: grid;
    grid-template-columns: repeat(2, 1fr);

    @include mixins.lg_s {
      font-size: font.$size--medium !important;
    }
  }

  &__row-label {
    padding: 0.3125rem 0;
    color: colors.$dark_grey_on_white;
  }

  &__row-value {
    padding: 0.3125rem 0;
    display: flex;
    text-align: right;
    color: colors.$amway_black;
    font-weight: bold;
    justify-content: flex-end;

    .awardProgressBadge__title {
      height: fit-content;
      text-wrap: pretty !important;
      text-align: center;

      @include mixins.lg_s {
        font-size: font.$size--medium !important;
      }
    }
  }

  &__title-link {
    padding-bottom: 0.375rem;
  }

  &__comingSoon {
    padding: 0.5rem;
    color: colors.$dark_grey_on_white;

    h2 {
      font-size: 1rem;
      margin: 0.25rem 0 0.5rem;
      padding: 0;
      color: colors.$amway_black;
    }
  }
}
