// font size
$size--standard: 1rem; /* 16px */
$size--big: 1.125rem; /* 18px */
$size--big_m: 1.375rem; /* 22px */
$size--big_s: 1.313rem; /* 21px */
$size--huge: 1.75rem; /* 28px */
$size--large: 1.5rem; /* 24px */
$size--large_m: 1.625rem; /* 26px */
$size--large_l: 2rem; /* 32px */
$size--large_xl: 3rem; /* 48px */
$size--medium: 0.875rem; /* 14px */
$size--medium_s: 0.813rem; /* 13px */
$size--small: 0.75rem; /* 12px */

// font family
$font-stack-source: "Source Sans Pro", "Helvetica Neue", helvetica, arial, "Sans Serif";
$family--fallback: sans-serif;
$family--amway-icons: "AmwayIcons";
$family--header: "GTWalsheimPro", $family--fallback;
$family--primary: "GTWalsheimPro", $family--fallback;
$family--primary--medium: "GTWalsheimProMedium", $family--fallback;
$family--primary--bold: "GTWalsheimProBold", $family--fallback;

// font weight
$weight--thin: 100;
$weight--regular: 400;
$weight--medium: 500;
$weight--semibold: 600;
$weight--bold: 700;
