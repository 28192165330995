@use "../../../common/scss/colors";
@use "../../../common/scss/font";
@use "../../../common/scss/mixins";

.leaf-radio-menu {
  gap: 0.75rem;
  display: flex;
  flex-direction: column;

  &__list-item {
    font-size: font.$size--standard;
    font-weight: font.$weight--medium;
    border: 0.063rem solid colors.$light_gray;
    border-radius: 0.75rem;
    padding: 1.125rem 1rem;
    display: flex;
    justify-content: space-between;
    position: relative;
    line-height: 1.25rem;

    &__text {
      width: 90%;
    }
  }

  &__list-item.selected {
    border: 0.125rem solid colors.$amway_black;
    color: colors.$amway_black;
    font-weight: font.$weight--medium;
  }

  // hide default radio button
  input[type="radio"] {
    display: none;
  }

  // custom radio button style
  &__list-item::before {
    width: 1.25rem;
    height: 1.25rem;
    content: "";
    border-radius: 50%;
    border: 0.125rem solid colors.$amway_black;
    margin-right: 1rem;
    position: absolute;
    right: 0;
    cursor: pointer;
  }

  // when the radio button is selected
  &__list-item.selected::before {
    border: 0.375rem solid colors.$amway_black;
  }
}

.leaf-radio-menu * {
  cursor: pointer;
}
