/* Red */
$light_pink: #f6bcc7;
$red: #ed174c;
$error_red: #d91734;
$outline_red: #eb8593;
$light_red: #ffe2e2;
$light_red_bg: #fef3f4;
$dark_red: #7f3e3e;
$background_light_red: #fdf4f4;
$dark_red_11: #7f3e3e;
$light_red_12: #ffe2e2;
$bright_red: #ff0100;
$cavernlight_pink: #e4bcba;
$data_viz_pink: #b6577e;

/* Gray - White - Black */
$mine_shaft: #373e41;
$mine_shaft_70: #373e41;
$dark_grey: #56565a;
$light_background: #e5e5e5;
$white: #fff;
$black: #000;
$amway_white: #f4f4f4;
$disabled: #d9d9d9;
$disabled_gray: #5e5e5e;
$amway_black: #2c2c2c;
$light_gray: #e4e4e4;
$medium_gray: #949494;
$very_light_gray: #fafafa;
$founders_platinum_gray: #57575b;
$dark_grey_on_white: #707070;
$light_gray_accent: #d6d6d6;
$light_gray_bg: #f0f0f0;
$light_gray_loading_bg: #e1e1e1;
$brunt_orange: #ce4800;
$han_blue: #5969d6;
$plum: #7e3e80;
$metallic_blue: #445685;
$teal: #007775;
$kobe: #7b2b00;
$blood: #9e0000;
$bell_flower: #573064;
$dark_shade_magenta: #574f5f;
$dark_shade_cyan: #1176a9;
$medium_green: #417539;
$footer-shadow: #2c2c2c1a;

/* Blue */
$blue: #38539a;
$dark_blue: #002f5f;
$founders_platinum_blue: #003061;
$light_blue: #e9f2fe;
$light_blue_6: #e5fdff;
$dark_blue_5: #396e75;

/* Purple */
$light_purple: #f1f2ff;
$purple: #94a3cd;
$dark_purple: #1e247f;
$dark_purple_2: #1b3067;
$background_purple: #1e247f1a;
$link_purple: #551a8b;

/* Orange */
$sandy_brown: #f89d6a;
$kournikova: #f8c86a;
$dark_orange: #a65523;
$darker_orange: #8f4700;
$light_orange: #fff1e8;
$orange: #a66400;
$extra_light_orange: #fff9f0;
$data_viz_orange: #da7600;
$outline_orange: #d1b278;
$light_orange_10: #fff1e8;
$dark_orange_9: #a65523;

/* Yellow */
$dark_yellow_13: #62625a;
$light_yellow_14: #f8f8e7;

/* Green */
$light_green: #107f47;
$light_green_8: #f0fae5;
$light_green_bg: $light_green_8;
$dark_green: #546223;
$solitude: #f0f8f2;
$green_outline: #7fbc96;
$banner_theme_backgrounds: (
  "green": $light_green_bg,
  "red": $light_red_bg,
  "yellow": $extra_light_orange,
  "blue": $light_purple,
  "dark": $amway_black,
);
$banner_theme_foregrounds: (
  "green": $light_green,
  "red": $red,
  "yellow": $orange,
  "blue": $blue,
  "dark": $white,
);
