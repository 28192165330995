@use "../../scss/colors";
@use "../../scss/font";

$width: 2.75rem;
$height: $width / 2;
$padding: 0.15rem;

.leafToggleSwitch {
  display: flex;
  flex-direction: row;
  gap: 1rem;
  height: $height;

  &__label {
    display: flex;
    padding: 0.1875rem 0;
    font-size: 1rem;
  }

  &__switch {
    position: relative;
    display: flex;
    width: $width - $padding;
    height: $height;
  }

  input[type="checkbox"] {
    height: $height;
    width: $width;
  }

  // not active
  &__switchKnob {
    position: absolute;
    cursor: pointer;
    background-color: colors.$medium_gray;
    border-radius: 1.5rem;
    inset: 0;
    transition: background-color 0.2s ease;
  }

  // active
  &__switchKnob::before {
    cursor: pointer;
    position: absolute;
    content: "";
    left: $padding;
    top: $padding;
    width: $height - 2 * $padding;
    height: $height - 2 * $padding;
    background-color: colors.$light_gray_bg;
    border-radius: 50%;
    transition: transform 0.3s ease;
  }

  input[type="checkbox"]:checked + &__switchKnob::before {
    transform: translateX(($width - 2 * $padding) / 2);
  }

  input[type="checkbox"]:checked + &__switchKnob {
    background-color: colors.$light_green;
  }
}
