@use "../../../scss/colors";
@use "../../../scss/font";
@use "../../../scss/mixins";

.leaf-tab-view-header-item {
  list-style-type: none;

  &__nav-item {
    line-height: 1.125rem;
    cursor: pointer;
    color: colors.$blue;
    border: none;
    background-color: colors.$white;
    font-size: font.$size--standard;
    font-weight: font.$weight--medium;

    @include mixins.sm_l {
      font-size: font.$size--medium;
    }

    &--active {
      font-weight: font.$weight--bold;
      text-decoration: underline 0.125rem;
      text-underline-offset: 0.375rem;
      cursor: pointer;
    }

    &--disabled {
      color: colors.$disabled;
      user-select: none;
      cursor: default;
    }
  }
}
