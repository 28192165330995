@use "../../scss/colors";
@use "../../scss/font";

.leaf-additional-reg-info {
  width: 100%;
  display: flex;
  flex-direction: column;
  height: 4.875rem;
  padding: 1rem;
  gap: 0.25rem;
  border-radius: 0.75rem;
  border: 0.063rem solid colors.$light_gray;

  &__title {
    font-size: font.$size--standard;
    font-weight: font.$weight--medium;
    line-height: 1.5rem;
    text-align: left;
  }

  &__sub-title {
    font-size: font.$size--medium;
    font-weight: font.$weight--regular;
    line-height: 1.125rem;
    text-align: left;
    color: colors.$dark_grey_on_white;
  }
}
