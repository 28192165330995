@use "../../../../scss/font";
@use "../../../../scss/colors";

.leaf-sponsor-type-label {
  font-size: 0.875rem;
  font-weight: font.$weight--medium;
  border-radius: 0.25rem;
  padding: 0.188rem 0.5rem;
  width: fit-content;

  &--sponsor {
    background-color: colors.$light_green_8;
    color: colors.$dark_green;
  }

  &--platinumSponsor {
    background-color: colors.$light_yellow_14;
    color: colors.$dark_yellow_13;
  }

  &--platinumAndSponsor {
    background-color: colors.$light_purple;
    color: colors.$blue;
  }

  &--internationalSponsor {
    background-color: colors.$light_red;
    color: colors.$dark_red;
  }

  &--fosterSponsor {
    background-color: colors.$light_gray_bg;
    color: colors.$disabled_gray;
  }

  &--platinumFosterSponsor {
    background-color: colors.$light_blue_6;
    color: colors.$dark_blue_5;
  }
}
