@use "mixins";

@mixin page_layout {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 2.5rem;

  @include mixins.less_than_desktop_inclusive {
    padding: 2.5rem 2rem;
  }

  @include mixins.md_s {
    padding: 1.5rem 1rem;
  }
}

.LockScrolling {
  position: fixed;
  left: 0;
  right: 0;
  width: 100%;
  overflow: hidden;
  touch-action: none;
}
