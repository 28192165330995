@use "../../scss/mixins";

.Icon-Clock {
  padding-top: 0.5rem;

  svg {
    width: 18.679rem;
    height: 13.25rem;

    @include mixins.md_s {
      width: 12.335rem !important;
      height: 8.75rem !important;
    }
  }
}
