@use "../../../scss/mixins";

.KeyMetrics {
  &__container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: auto;
    grid-gap: 1rem;

    @include mixins.over_lg {
      grid-template-columns: repeat(3, 1fr);
      grid-template-rows: auto;
      grid-gap: 1rem;
    }

    @include mixins.from_tablet {
      @include mixins.less_than_desktop_inclusive {
        grid-template-columns: repeat(3, 1fr);
        grid-template-rows: auto;
        grid-gap: 1rem;
      }
    }

    @include mixins.sm_l {
      grid-template-columns: 1fr;
      grid-template-rows: auto;
      grid-gap: 1rem;
    }
  }

  &__header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 0.5rem;

    &--icon {
      width: 1rem;
      height: 1rem;
    }
  }

  &__kpi-data {
    font-size: 1.75rem;
    font-weight: 700;
  }

  &__kpi-open {
    display: block;
    padding-top: 0.25rem;

    &--country {
      display: flex;
      justify-content: space-between;
      padding-left: 1rem;
      padding-right: 0.25rem;
    }
  }

  &__kpi-closed {
    display: none;
  }
}
