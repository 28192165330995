@use "../../../../common/scss/mixins";
@use "../../../../common/scss/font";

.abo-profile-perf-body {
  padding-top: 0;

  @include mixins.from_tablet {
    padding-top: 1.5rem;
  }

  .abo-profile-perf-history__options__kpis {
    min-width: auto;
    flex-grow: 2;
  }

  .abo-profile-perf-history__options__py {
    min-width: auto;
    flex-grow: 1;
  }
}
