@use "../../scss/colors";
@use "../../scss/font";
@use "../../scss/mixins";
@use "../../scss/layers";

.leaf-modal {
  &__backdrop {
    position: fixed;
    background: rgb(112 112 112 / 70%);
    width: 100vw;
    height: 100vh;
    inset: 0;
    z-index: layers.$backdrop;
  }

  &__modal-container {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    align-items: stretch;
    line-height: 1.4;
    background: colors.$white;
    border-radius: 1.25rem;
    border: 1px colors.$light_gray;
    color: colors.$amway_black;
    width: 41.25rem;
    max-height: 80%;
    max-width: 91.5vw;
    min-width: 25vw;
    z-index: layers.$modal;
    font-family: font.$font-stack-source;
    padding-top: 1rem;
    padding-bottom: 3rem;

    @include mixins.md_s {
      width: 100%;
      height: 100%;
    }
  }

  &__modalHeader {
    font-weight: font.$weight--bold;
    font-size: font.$size--big_m;
    margin: 0.5rem 1rem 0.25rem;

    h1 {
      padding: 0;
      font-size: font.$size--big_m;
    }
  }

  &__title {
    font-weight: font.$weight--bold;
    font-size: font.$size--big_m;
  }

  &__modal-body {
    height: 100%;
    padding: 0 0 1.5rem;
    font-size: font.$size--standard;
    font-weight: font.$weight--regular;

    @include mixins.md_s {
      font-size: font.$size--medium;
      overflow-x: auto;
    }
  }

  &__modal-subtitle {
    padding-top: 0.3rem;
    font-weight: font.$weight--bold;
    font-size: font.$size--big !important;
    color: colors.$blue;
  }

  &__modal-desc {
    font-size: font.$size--big;
    margin: 0 0 0.25rem;

    @include mixins.md_s {
      font-size: font.$size--medium;
    }
  }

  &__close-icon {
    display: flex;
    justify-content: flex-end;
    padding: 0 1.5rem;

    &__close {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      position: relative;
      height: fit-content;
      width: fit-content;
      background-color: colors.$white;

      &::before {
        content: "";
        position: absolute;
        top: 60%;
        left: 65%;
        transform: translate(-50%, -50%);
        width: 3rem;
        height: 3rem;
        border-radius: 50%;
        z-index: 1;
        transition: colors.$white 0.3s ease;
        cursor: pointer;
      }

      svg {
        position: relative;
        width: 2rem;
        height: 2rem;
        z-index: 2;

        > * {
          cursor: pointer;
        }
      }

      &:hover::before {
        background-color: rgb(112 112 112 / 6%);
      }
    }

    .leaf-modal__close-icon__close {
      border: none !important;
    }
  }

  &__modal-content {
    height: 100%;
    width: 100%;
    padding: 0 1rem;
    box-sizing: border-box;
    max-height: calc(100% - 2rem);
    overflow: hidden auto;
  }
}
