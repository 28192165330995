@use "../../scss/colors";

.icon-close {
  display: block;
  width: 0.9375rem;
  height: 1.375rem;

  &__black {
    svg > * {
      fill: colors.$amway_black;
    }
  }

  &__grey {
    svg > * {
      fill: colors.$disabled_gray;
    }
  }

  &__white {
    svg > * {
      fill: colors.$white;
    }
  }

  &:hover > * {
    cursor: pointer !important;

    svg > * {
      cursor: pointer !important;
    }
  }
}
