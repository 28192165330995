@use "../../common/scss/colors";
@use "../../common/scss/font";

.EdLos {
  &__custom-error {
    margin: 0 2.5rem 1rem;
    padding: 2.5rem 1.5rem;
    border: 0.063rem solid colors.$light_gray;
    border-radius: 0.75rem;

    &--loading {
      border: none;
      padding: 0;
    }
  }

  &__wrapper {
    display: flex;
    flex-direction: column;
    min-height: 46rem;
  }

  .ErrorTile__error-msg {
    font-size: font.$size--big;
    color: colors.$amway_black;
    font-weight: font.$weight--regular;
  }
}
