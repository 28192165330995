%iconBase {
  display: block;
  height: 2.5rem;
  width: 2.5rem;
}

.icon-gear {
  @extend %iconBase;

  &__gray {
    @extend %iconBase;
  }

  &__blue {
    @extend %iconBase;
  }
}
