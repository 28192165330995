@use "../../../../../common/scss/mixins";

.MonthlyPerformanceProgressBar {
  &__oneQlegMilestone {
    display: flex;
    flex-direction: row;
    gap: 0.25rem;

    @include mixins.sm_l {
      flex-direction: column;
      gap: 0;
    }
  }
}
