@use "../../../../scss/font";
@use "../../../../scss/colors";

.leaf-sponsor-detail {
  padding: 1rem;
  border: solid colors.$light_gray 0.063rem;
  border-radius: 0.75rem;

  &__header {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    font-weight: font.$weight--medium;

    &--details {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      gap: 0.25rem;
    }

    &--abo-number {
      font-size: 0.875rem;
      font-weight: font.$weight--regular;
    }
  }

  &__body {
    gap: 1rem;
    display: flex;
    flex-direction: column;
  }
}
