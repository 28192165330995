@use "../../common/scss/mixins";

.router-loader {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 1.5rem;
  margin: 1.5rem;
  width: 100%;

  &-header,
  &-top {
    width: 100%;
    display: flex;
    flex-flow: row wrap;
  }

  &-top-left,
  &-top-right {
    width: 49%;
    display: flex;
    flex-direction: column;
  }

  &-top-right {
    justify-content: space-between;
  }

  &-bottom {
    width: 100%;
    display: flex;
    flex-direction: column;
  }

  @include mixins.md {
    &-header,
    &-top,
    &-top-left,
    &-top-right {
      gap: 0;
      margin: 0;
      width: 95%;
    }
  }
}

.mbi {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  margin: 0 2.5rem;

  @include mixins.lg_s {
    margin: 0 2rem;
  }

  @include mixins.md {
    margin: 0 1rem;
  }

  h1.mbi__header {
    padding-left: 0;
    padding-right: 0;
  }

  &__header-container {
    .PageTitleSection {
      padding-left: 0;
      padding-right: 0;
    }
  }
}
