@use "../../scss/mixins";

.Icon-Unavailable {
  svg {
    width: 18.75rem;
    height: 16rem;

    @include mixins.md_s {
      width: 12.059rem !important;
      height: 10.5rem !important;
    }
  }

  padding-top: 0.5rem;
}
