@use "../../scss/colors";
@use "../../scss/mixins";
@use "../../scss/layers";

%modalBase {
  background-color: colors.$white;
  position: fixed;
  height: 100%;
  width: 30rem;
  bottom: 0;
  top: 0;
  overflow: hidden auto !important;
  z-index: layers.$modal;
  color: colors.$amway_black;

  @include mixins.md_s {
    width: 100% !important;
    bottom: 0;
    overflow: hidden auto !important;
  }
}

%cloud {
  background-image: url("../../icons/IconBgCloud/svg/icon_cloud.svg");
  background-repeat: no-repeat;
  background-position: bottom;
  background-size: contain;
}

.leaf-panel {
  @extend %modalBase;

  @include mixins.md_s {
    margin-top: 5em;
  }

  &__backdrop {
    width: 100%;
    height: 100%;
    position: fixed;
    inset: 0;
    z-index: layers.$backdrop;
    background: rgb(112 112 112 / 70%);
  }

  &__left {
    @extend %modalBase;

    left: 0;
  }

  &__right {
    @extend %modalBase;

    right: 0;
  }

  &--displayed {
    transform: translateX(0);

    @include mixins.sm_l {
      transform: translateY(0%);
    }
  }

  &--dismissed {
    transform: translateX(30rem);

    @include mixins.sm_l {
      transform: translateY(100%);
    }
  }

  &__draggable {
    display: none;
    overflow: hidden auto !important;

    @include mixins.md_s {
      padding-top: 0.625rem;
      justify-content: center;
      display: flex;
      align-items: center;
    }

    &--line {
      @include mixins.md_s {
        width: 3.125rem;
        height: 0.188rem;
        background-color: colors.$disabled_gray;
        border-radius: 1rem;
      }
    }
  }

  &__fullsize {
    @extend %modalBase;

    width: 100%;
  }

  &__header {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 1rem;
    gap: 0.625rem;
    height: 7%;
    border-bottom: 1px solid #e4e4e4;

    h1 {
      padding: 0;
    }
  }

  &__closeBtn {
    border: 0;
    background: none;
    padding: 0.5rem 0.75rem;
    margin-left: 0 !important;
    border-radius: 100%;
  }

  &__closeBtn:hover {
    background-color: rgb(112 112 112 / 6%);
    cursor: pointer;
  }

  &__closeBtn > *:hover {
    cursor: pointer;
  }

  &__container {
    height: 100%;
  }

  &__header-title {
    margin-right: -2.75rem;
    font-size: 1rem;
    font-weight: 700;
    text-align: center;
    flex: 1;

    @include mixins.md_s {
      font-size: 1.3rem;
    }
  }

  &__body {
    padding: 1rem;
    overflow: hidden auto !important;
  }

  &__footer {
    position: absolute;
    bottom: 0;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 1rem;
    padding: 1rem;

    @include mixins.md_s {
      display: none;
    }
  }

  &__bg {
    &--veryLightGrey {
      background-color: colors.$very_light_gray;
    }

    &--cloud {
      @extend %cloud;
    }
  }
}
