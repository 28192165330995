@use "../../../../../../common/scss/colors";
@use "../../../../../../common/scss/font";
@use "../../../../../../common/scss/mixins";

.server-side-export-download-loader {
  display: flex;
  flex-direction: column;

  &__export-detail {
    border-radius: 0.75rem;
    border: 0.0125rem solid colors.$light_gray;
    background: colors.$white;
    gap: 0.5rem;
    padding: 1.125rem 1rem;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    align-self: stretch;
    margin-bottom: 0.625rem;
  }

  &__divider-line {
    width: 100%;
    border: 0.063rem solid colors.$light_gray;
    margin-bottom: 0.625rem;
  }
}
