@use "../../../scss/colors";

.leaf-profile-loader {
  &--applicant-card {
    border: 0.0625rem solid colors.$light_gray;
    border-radius: 0.75rem;
    padding: 1rem 1.5rem;
    margin-bottom: 0.5rem;
    align-items: center;
    background-color: colors.$white;
    height: 10rem;
    padding-top: 6rem;
  }

  &--active-card {
    border: 0.0625rem solid colors.$light_gray;
    border-radius: 0.75rem;
    padding: 1rem 1.5rem;
    margin-bottom: 0.5rem;
    align-items: center;
    background-color: colors.$white;
  }
}
