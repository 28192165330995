@use "../../../../common/scss/colors";
@use "../../../../common/scss/font";
@use "../../../../common/scss/mixins";

.monthly-bonus-modal {
  display: flex;
  justify-content: center;
  flex-direction: column;

  .leaf-modal__modal-container {
    height: auto;
  }

  &_bonus-header {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0;
    gap: 0.25rem;
    text-align: center;
    margin-bottom: 1rem;

    h2 {
      font-weight: font.$weight--bold;
      font-size: font.$size--big_s;
      line-height: 1.75rem;
    }

    p {
      font-size: font.$size--standard;
      font-weight: font.$weight--regular;
      line-height: 1.5rem;

      @include mixins.less_than_tablet {
        font-size: font.$size--medium;
        line-height: 1.125rem;
      }
    }
  }

  &_content {
    display: flex;
    flex-direction: column;
    gap: 2rem;
  }

  &_bonus-details {
    display: flex;
    flex-direction: column;

    .wrapper {
      &:not(:first-child) {
        margin-top: 2rem;
      }
    }

    .abo {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      background: colors.$amway_white;
      padding: 0.5rem 1.25rem;
      line-height: 1.313rem;
      font-weight: font.$weight--bold;
      font-size: font.$size--big;

      @include mixins.less_than_tablet {
        font-size: font.$size--standard;
        line-height: 1.15rem;
      }
    }

    .abo-details {
      color: colors.$dark_grey_on_white;
      font-weight: font.$weight--regular;
      font-size: font.$size--standard;
      line-height: 1.5rem;

      @include mixins.less_than_tablet {
        font-size: font.$size--medium;
        line-height: 1.125rem;
      }
    }

    .personal-bv,
    .bonus-percent,
    .current-total {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      font-size: font.$size--standard;
      line-height: 1.5rem;
      padding: 0.625rem 1.25rem;
      align-items: center;

      @include mixins.less_than_tablet {
        font-size: font.$size--medium;
        line-height: 1.125rem;
      }
    }

    .bonus-percent {
      border-bottom: 0.063rem solid colors.$light_gray_accent;
    }

    .current-total {
      font-size: font.$size--standard;
      font-weight: font.$weight--bold;
      line-height: 1.25rem;
      margin: 0.5rem 0;

      @include mixins.less_than_tablet {
        font-size: font.$size--medium;
        line-height: 1.125rem;
      }
    }

    .non-bonusable-leg {
      display: flex;
      flex-wrap: wrap;
      background: colors.$extra_light_orange;
      border: 0.063rem solid colors.$outline_orange;
      border-radius: 0.25rem;
      justify-content: space-between;
      padding: 0.5625rem 0.625rem;
      color: colors.$orange;
      margin-bottom: 1rem;
      font-size: font.$size--standard;
      line-height: 1.5rem;

      @include mixins.less_than_tablet {
        font-size: font.$size--medium;
        line-height: 1.125rem;
      }

      &_content {
        font-size: font.$size--standard;
        font-weight: font.$weight--bold;
        line-height: 1.5rem;
        display: flex;
        gap: 0.5rem;
        align-items: center;

        .icon-alert {
          scale: 1;
        }

        @include mixins.less_than_tablet {
          font-size: font.$size--medium;
          line-height: 1.125rem;
        }
      }
    }

    .personal-bonus-total {
      display: flex;
      flex-wrap: wrap;
      background: colors.$amway_black;
      color: colors.$white;
      justify-content: space-between;
      padding: 1rem;
      font-weight: font.$weight--bold;

      &_text {
        line-height: 1.3125rem;
        font-size: font.$size--big;
        align-self: center;
      }

      &_amount {
        line-height: 1.75rem;
        font-size: font.$size--large;
      }

      @include mixins.less_than_tablet {
        &_text {
          font-size: font.$size--standard;
          line-height: 1.25rem;
          align-self: center;
        }

        &_amount {
          line-height: 1.3125rem;
          font-size: font.$size--big;
        }
      }
    }
  }

  &__footer {
    .bonus-footer-container {
      padding: 0;
      margin: 0;
    }
  }
}
