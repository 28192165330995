@use "../../../../../../../common/scss/colors";
@use "../../../../../../../common/scss/font";

.abo-profile-primary-address {
  &__header {
    font-size: font.$size--standard;
    padding: 1.5rem 0 1rem;
  }

  &__content {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;

    > :nth-child(n) {
      border-radius: 0.75rem;
      border: 0.0625rem solid colors.$light_gray;
      padding: 1rem;
    }
  }
}
