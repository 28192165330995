@use "../../scss/colors";
@use "../../scss/font";

.leaf-link-button {
  cursor: pointer;

  &__link {
    text-underline-offset: 0.25rem;

    &__theme-purple {
      &--enabled {
        color: colors.$dark_purple;
      }

      &--disabled {
        color: colors.$disabled_gray;
        cursor: not-allowed;

        > a {
          cursor: not-allowed;
          pointer-events: none;
        }
      }
    }

    &__theme-white {
      &--enabled {
        color: colors.$white;
      }

      &--disabled {
        color: colors.$disabled;
        cursor: not-allowed;

        > a {
          cursor: not-allowed;
          pointer-events: none;
        }
      }
    }

    &__theme-black {
      &--enabled {
        color: colors.$black;
      }

      &--disabled {
        color: colors.$dark_grey_on_white;
        cursor: not-allowed;

        > a {
          cursor: not-allowed;
          pointer-events: none;
        }
      }
    }

    &__theme-green {
      &--enabled {
        color: colors.$light_green;
      }

      &--disabled {
        color: colors.$green_outline;
        cursor: not-allowed;

        > a {
          cursor: not-allowed;
          pointer-events: none;
        }
      }
    }

    &__theme-blue {
      &--enabled {
        color: colors.$blue;
      }

      &--disabled {
        color: colors.$disabled;
        cursor: not-allowed;

        > a {
          cursor: not-allowed;
          pointer-events: none;
        }
      }
    }

    &__underline {
      &--enabled {
        text-decoration: 0.125rem underline;
      }

      &--disabled {
        text-decoration: none;
      }
    }
  }

  &__button {
    display: flex;
    padding: 0.563rem 2rem;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;
    align-self: stretch;
    border-radius: 6.25rem;
    border: 0.125rem solid;
    width: 100%;
    background: colors.$white;

    &__theme-white {
      &--enabled {
        border-color: colors.$amway_black;
        color: colors.$black;
      }

      &--disabled {
        border-color: colors.$disabled;
        cursor: not-allowed;
        color: colors.$dark_grey_on_white;
      }
    }

    &__theme-black {
      &--enabled {
        background: colors.$amway_black;
        border-color: colors.$amway_black;
        color: colors.$white;
      }

      &--disabled {
        background: colors.$disabled;
        border-color: colors.$disabled;
        cursor: not-allowed;
        color: colors.$very_light_gray;
      }
    }

    &__theme-green {
      &--enabled {
        background: colors.$light_green;
        border-color: colors.$light_green;
        color: colors.$white;
      }

      &--disabled {
        background: colors.$green_outline;
        border-color: colors.$green_outline;
        cursor: not-allowed;
        color: colors.$very_light_gray;
      }
    }

    &__theme-blue {
      &--enabled {
        background: colors.$blue;
        border-color: color__blue;
        color: colors.$white;
      }

      &--disabled {
        background: colors.$disabled;
        border-color: colors.$disabled;
        cursor: not-allowed;
        color: colors.$very_light_gray;
      }
    }
  }

  &--normal {
    font-size: font.$size--standard;
  }

  &--big {
    font-size: font.$size--big;
  }

  &--huge {
    font-size: font.$size--huge;
  }

  &--large {
    font-size: font.$size--large;
  }

  &--medium {
    font-size: font.$size--medium;
    font-weight: font.$weight--medium;
  }

  &--small {
    font-size: font.$size--small;
  }

  &--thin {
    font-weight: font.$weight--thin;
  }

  &--regular {
    font-weight: font.$weight--regular;
  }

  &--semibold {
    font-weight: font.$weight--semibold;
  }

  &--bold {
    font-weight: font.$weight--bold;
  }
}
