@use "colors";

@keyframes color-shift {
  0% {
    color: transparent;
    background: #bbb;
  }

  50% {
    color: transparent;
    background: #eee;
  }

  100% {
    color: transparent;
    background: #bbb;
  }
}

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }

  50% {
    background-position: 100% 50%;
  }

  100% {
    background-position: 0% 50%;
  }
}

@keyframes pulse {
  50% {
    opacity: 0.5;
  }
}

%loading-pulse {
  background: colors.$light_gray_loading_bg;
  animation: pulse 2s ease infinite;
  border-radius: 0.25rem;
}

.loading-pulse {
  @extend %loading-pulse;
}
