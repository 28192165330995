@use "../../../scss/colors";
@use "../../../scss/font";
@use "../../../scss/layers";

$itemHeight: 2.5rem;

.WheelPickerColumn {
  padding: 2 * $itemHeight 0;
  flex-direction: column;
  height: calc($itemHeight * 5);
  overflow: hidden;
  overflow-y: scroll;
  scroll-snap-type: y mandatory;
  scroll-margin: $itemHeight 0;
  scroll-behavior: smooth;
  z-index: layers.$content;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  border: none;
  width: 100%;
  background: transparent;
  font-size: font.$size--big;
  line-height: $itemHeight;
  color: colors.$disabled;
  mask-image: linear-gradient(0deg, transparent, transparent 1%, #fff 20%, white 80%, transparent 99%, transparent);

  &__container {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    scroll-snap-align: center;
    gap: 0.5rem;
    overflow: hidden;
    border: none;
    height: $itemHeight !important;
    line-height: $itemHeight;
    background: transparent;

    & > span {
      width: auto;
    }

    & > span:first-child {
      margin: 0 0.5rem;
    }

    & > span:last-child {
      overflow: hidden;
    }
  }

  &__item {
    %item {
      user-select: none;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;

      &--leftAligned {
        justify-content: flex-start !important;
      }

      &--centeredAligned {
        justify-content: center !important;
      }

      &--rightAligned {
        justify-content: flex-end !important;
      }

      &--active {
        scroll-snap-align: center;
        font-weight: font.$weight--semibold;
        color: colors.$blue !important;
      }

      &--disabled {
        background: colors.$disabled;
        color: colors.$white;
        border-radius: 0.75rem;
      }
    }

    @extend %item;
  }

  // &__item > * {
  //   @extend .WheelPickerColumn__item;
  // }

  &__left-col,
  &__right-col {
    width: 50%;
  }

  &__left-col {
    text-align: right;
  }

  &__right-col {
    text-align: left;
  }

  :focus {
    border: none;
    outline: 0;
  }

  /* Chrome and Safari */
  ::-webkit-scrollbar {
    display: none;
  }
}
