@use "../../../scss/font";
@use "../../../scss/colors";

@mixin leaf-info {
  &__content-title {
    font-weight: font.$weight--medium;
    font-size: font.$size--medium;
    line-height: font.$size--large;
  }

  &__content-data {
    font-weight: font.$weight--regular;
    font-size: font.$size--medium;
    line-height: font.$size--big;
    color: colors.$dark_grey_on_white;
  }
}
