@use "../../scss/colors";
@use "../../scss/font";
@use "../../scss/mixins";

.performanceProgressBar {
  position: relative;
  margin: 1rem 0.5rem 0;
  font-weight: font.$weight--bold;

  &__container {
    position: relative;
  }

  &__caret {
    position: absolute;
    bottom: 0.2rem;

    svg {
      height: 1rem;
    }
  }

  &__bar-text {
    position: relative;
    transform: translateX(-50%);
    text-align: center;
    font-size: font.$size--standard;
    line-height: font.$size--standard;
    top: -0.313rem;
    padding: 0 0.625rem;

    @include mixins.less_than_tablet {
      max-width: 55%;
      transform: translateX(0);
      display: flex;
      flex-wrap: wrap;
    }
  }

  &__long-bar {
    @include mixins.less_than_tablet {
      justify-content: flex-end;
    }
  }

  &__long-space {
    @include mixins.less_than_tablet {
      max-width: 85%;
    }
  }

  &__bar-text-target,
  &__bar-slash {
    font-size: font.$size--medium;
    font-weight: font.$weight--medium;
    color: colors.$dark_grey_on_white;
  }

  &__bar-text-target {
    @include mixins.less_than_tablet {
      padding-right: 0.6rem;
    }
  }

  &__progress-contatiner {
    display: flex;
    align-items: center;
    width: 100%;
    height: 1.25rem;
    background-color: colors.$light_gray;
    border-radius: 0.312rem;
    position: relative;
  }

  &__label {
    font-size: font.$size--large;
    width: 100%;
  }

  &__value {
    display: flex;
    width: 100%;
    font-weight: font.$weight--medium;
    color: colors.$dark_grey_on_white;
    text-wrap: pretty;
  }

  &__progress-filled {
    height: 100%;
    border-radius: 0.312rem;
  }

  &__labels-values {
    display: flex;
    top: 1.25rem;
    justify-content: space-between;
    margin-top: 0.625rem;
    font-size: font.$size--medium;
    flex: 1;

    &--milestone {
      align-items: center;
      text-align: center;
      flex-direction: column;
      justify-content: flex-end;
    }
  }

  &__labels-values--milestone:first-child {
    display: flex;
    flex: 1;
    text-align: left;
    justify-content: flex-start;
    align-items: baseline;

    @include mixins.less_than_tablet {
      flex-wrap: wrap;
    }
  }

  &__labels-values--milestone:last-child {
    display: flex;
    flex: 1;
    justify-content: flex-end;
    align-items: baseline;
    text-align: right;

    .performanceProgressBar__value {
      justify-content: flex-end;
    }
  }

  &__milestones {
    position: absolute;
    top: 0;
    height: 100%;
    width: 1px;
    background-color: colors.$white;
  }
}
