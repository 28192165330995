%iconBase {
  display: flex;
  width: 2.5rem;
  height: 2.5rem;
  cursor: pointer;
}

.icon-table {
  @extend %iconBase;

  &__blue {
    @extend %iconBase;
  }
}
