@use "../../../../common/scss/mixins";
@use "../../../../common/scss/font";
@use "../../../../common/scss/colors";

.FppReqTile {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  height: 100%;

  &__top-section {
    min-height: 12rem;
  }

  &__title {
    font-size: font.$size--medium;
    font-weight: font.$weight--medium;
    line-height: 1.125rem;
    margin-bottom: 1rem;
    color: colors.$amway_black;
  }

  &__shield-section {
    display: flex;
    justify-content: space-between;

    @include mixins.md_s {
      justify-content: center !important;
      gap: 4rem !important;
    }
  }

  &__shield-text {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    max-width: 5rem;
    padding: 0 1rem;
    font-size: font.$size--medium;
  }

  &__shield-item {
    margin: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    max-width: 4rem;
    font-size: font.$size--medium;
  }

  &__needed-section {
    padding: 0.5rem 0;
    min-height: 4rem;

    @include mixins.md_s {
      height: auto;
    }
  }

  &__not-available {
    font-size: font.$size--medium;
    color: colors.$dark_grey_on_white;
    padding-bottom: 2rem;
  }

  &__closed-text {
    font-size: font.$size--medium;
    color: colors.$dark_grey_on_white;
    line-height: font.$size--big_m;
  }

  &__open-text {
    font-size: font.$size--medium;
    color: colors.$orange;
    line-height: font.$size--big_m;
  }

  &__reduced-font {
    font-size: font.$size--small;

    @include mixins.md_s {
      font-size: font.$size--medium;
    }
  }

  &__bottom-section {
    padding: 0.6rem 0;
  }

  &__current-title {
    color: colors.$dark_grey_on_white;
    font-size: font.$size--medium;
    font-weight: font.$weight--regular;
    padding-bottom: 0.5rem;
  }

  &__current-requirements {
    background-color: colors.$white;
    color: colors.$amway_black;
    font-size: 0.875rem;
    font-weight: font.$weight--medium;
    height: auto;
  }

  &__container {
    max-width: 15%;
    width: 100%;

    @include mixins.md_s {
      max-width: 100%;
    }
  }
}
