.abo-profile-bgt {
  .leaf-profile-loader--active-card {
    height: 6.625rem;
    margin: 1rem 0;
  }

  .aboTrackingTile__incentiveTags {
    margin-bottom: 0.315rem;
  }

  ul {
    list-style: none;
  }
}
