@use "../../scss/colors";

%tag {
  background-color: none;
  color: colors.$black;
  padding: 0.188rem 0.5rem;
  border-radius: 0.25rem;
}

.leaf-tag {
  &__default {
    @extend %tag;
  }

  &__orange {
    @extend %tag;

    background-color: colors.$extra_light_orange;
    color: colors.$orange;
  }

  &__medOrange {
    @extend %tag;

    background-color: colors.$light_orange_10;
    color: colors.$dark_orange_9;
  }

  &__green {
    @extend %tag;

    background-color: colors.$light_green_bg;
    color: colors.$light_green;
  }

  &__medGreen {
    @extend %tag;

    background-color: colors.$light_green_8;
    color: colors.$dark_green;
  }

  &__darkGreen {
    @extend %tag;

    background-color: colors.$dark_green;
    color: colors.$white;
  }

  &__purple {
    @extend %tag;

    background-color: colors.$light_purple;
    color: colors.$blue;
  }

  &__gray {
    @extend %tag;

    background-color: colors.$light_gray_bg;
    color: colors.$disabled_gray;
  }

  &__blue {
    @extend %tag;

    background-color: colors.$light_purple;
    color: colors.$blue;
  }

  &__lightBlue {
    @extend %tag;

    background-color: colors.$light_blue_6;
    color: colors.$dark_blue_5;
  }

  &__red {
    @extend %tag;

    background-color: colors.$background_light_red;
    color: colors.$error_red;
  }

  &__medRed {
    @extend %tag;

    background-color: colors.$light_red_12;
    color: colors.$dark_red_11;
  }

  &__darkRed {
    @extend %tag;

    background-color: colors.$dark_red_11;
    color: colors.$white;
  }

  &__lightRed {
    @extend %tag;

    background-color: colors.$light_red;
    color: colors.$dark_red_11;
  }

  &__lightYellow {
    @extend %tag;

    background-color: colors.$light_yellow_14;
    color: colors.$dark_yellow_13;
  }

  &__darkOrange {
    @extend %tag;

    background-color: colors.$dark_orange_9;
    color: colors.$white;
  }
}
