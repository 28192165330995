@use "../../../../../common/scss/colors";
@use "../../../../../common/scss/font";
@use "../../../../../common/scss/mixins";

.differential-bonus-modal {
  &__export-button {
    align-self: self-end;
    margin-top: 0.5rem;

    @include mixins.less_than_tablet {
      display: flex;
      justify-content: center;
      width: 100%;
    }
  }

  &__export-title-section {
    display: flex;
  }

  &__export-title {
    line-height: 1rem;
    font-weight: 700;
    padding-right: 0.5rem;
    cursor: pointer;
  }
}
