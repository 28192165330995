@use "../../common/scss/colors";
@use "../../common/scss/font";
@use "../../common/scss/mixins";

.mobile-header {
  height: 100%;
  max-height: 5.938rem;
  width: 100%;
  display: flex;
  flex-direction: row;
  background-color: colors.$white;
  padding: 1rem 0.938rem;
  margin-bottom: 0.625rem;
  color: colors.$amway_black;
  box-shadow: 0 0.125rem 0.25rem colors.$light_background;

  @include mixins.sm {
    flex-flow: column wrap;
    padding: 0.625rem 0.938rem;
  }

  &--headless {
    padding: 0.5rem;
    flex-flow: row nowrap;
    justify-content: flex-end;
    margin-bottom: 0;
  }

  &__hamburger {
    display: flex;
    flex-direction: row;
    margin-right: 0.938rem;

    @include mixins.sm {
      margin: 0.625rem 0;
    }

    div {
      align-self: center;
    }
  }

  &__heading {
    display: flex;
    flex-direction: column;
    font-size: font.$size--big;
    justify-content: center;
    overflow: hidden;
    max-width: 50vw;

    @include mixins.sm {
      margin-bottom: 1.25rem;
    }
  }

  &__aboInfo {
    padding: 0 !important;
    margin: 0 !important;
    max-width: 75vw;

    h1,
    h2 {
      white-space: nowrap;
      text-wrap: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }

    h1 {
      font-size: 1.54rem;
    }

    h2 {
      font-size: 1.25rem;
      font-weight: font.$weight--thin;
    }

    &--desktop {
      display: flex;
      flex-direction: column;

      * > {
        align-self: flex-end;
      }
    }

    &--mobile {
      width: 100%;
    }
  }

  &__primary-business-name {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  &__abo-number {
    font-size: font.$size--medium;
  }

  &__period-selector {
    margin-left: auto;
    align-self: center;

    @include mixins.sm {
      margin-bottom: 0.625rem;
      margin-left: 0;
    }

    &--headless {
      margin: 0;
    }
  }
}
