@use "../../scss/colors";
@use "../../scss/font";

%leaf-button-base {
  all: unset; // remove default button styling
  background-color: transparent;
  height: 2.75rem;
  border: 0.125rem solid colors.$amway_black;
  border-radius: 3.125rem;
  padding: 0.5rem;
  cursor: pointer;
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  font-family: font.$family--primary;
  font-weight: font.$weight--bold;
  font-size: font.$size--standard;
  box-sizing: border-box;
}

.LeafButton {
  @extend %leaf-button-base;

  &__default,
  &__outline {
    @extend %leaf-button-base;
  }

  &__solid {
    background-color: colors.$amway_black;
    color: colors.$white;
  }

  &__link-icon,
  &__icon {
    display: flex;
    margin: 0 0.3rem 0.1rem;
  }

  &__blue {
    border-radius: 1.5rem;
    background-color: colors.$blue;
    justify-content: center;
    align-items: center;
    color: colors.$white;
    width: 100%;
    border: none;
    margin: 1rem 0;
    padding: 0.5625rem 2rem;
    font-size: font.$size--big;
    cursor: pointer;

    &--disabled {
      cursor: not-allowed;
      background-color: colors.$disabled;
    }
  }
}
