@use "../../scss/mixins";

.icon-no-data {
  display: block;
  background: url("./svg/nodata.svg") no-repeat;
  height: 25rem;
  width: 33.75rem;

  @include mixins.sm_xl {
    height: 18rem;
    width: 21rem;
  }
}
