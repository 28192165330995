@use "../../../../../common/scss/colors";
@use "../../../../../common/scss/font";

.BusinessTile {
  &__container {
    display: grid;
  }

  &__bus-info {
    display: flex;
    justify-content: space-between;
    font-weight: font.$weight--semibold;
    padding-bottom: 0.5rem;
  }

  &__bus-info-flag {
    display: flex;
    justify-content: space-between;
    padding-bottom: 0.3125rem;
    font-weight: font.$weight--semibold;
  }

  &__primary {
    display: flex;
    place-content: center;
    justify-content: space-between;
    gap: 0.3125rem;
    color: colors.$blue;
    background-color: colors.$light_purple;
    padding: 0.1875rem 0.5rem;
    border-radius: 0.25rem;
  }

  &__bus-name {
    text-align: end;
    overflow-wrap: anywhere;
    padding-left: 0.3125rem;
    text-transform: capitalize;
    line-height: 1.3125rem;
    cursor: pointer;
    font-weight: font.$weight--bold;
    color: colors.$blue;
    text-decoration: underline;
    text-underline-offset: 0.3rem;
    text-decoration-thickness: 0.125rem;

    &:hover {
      color: colors.$dark_purple_2 !important;
    }
  }

  &__current-abo {
    font-weight: font.$weight--bold;
    color: colors.$dark_purple_2 !important;
    cursor: revert !important;
  }

  &__bus-abo-no {
    cursor: pointer;
    font-weight: font.$weight--bold;
    color: colors.$blue;
    text-decoration: underline;
    text-underline-offset: 0.3rem;
    text-decoration-thickness: 0.125rem;
    line-height: 1.3125rem;

    &:hover {
      color: colors.$dark_purple_2;
    }
  }

  &__bus-info-primary {
    font-size: font.$size--medium;
    line-height: 1.125rem;
  }

  &__bus-values {
    display: flex;
    justify-content: space-between;
    padding: 0.625rem 0;
    align-items: center;
  }

  &__bus-values:last-child {
    padding-bottom: 0 !important;
  }

  &__info-label {
    font-size: font.$size--medium;
    color: colors.$dark_grey_on_white;
  }

  &__value {
    font-size: font.$size--large;
    font-weight: font.$weight--bold;
  }

  &__bus-country-info {
    display: flex;
    align-items: center;
    color: colors.$dark_grey_on_white;
    font-weight: font.$weight--medium;
  }

  &__bus-country-name {
    padding-left: 0.625rem;
  }
}
