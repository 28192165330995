@use "../../common/scss/colors";
@use "../../common/scss/mixins";
@use "../../common/scss/font";

%input {
  background-color: white;
  width: 100%;
  padding: 0.75rem;
  font-size: 1rem;
  display: inline-block;
  border: 1px solid colors.$amway_black;
  border-radius: 0.75rem;
  vertical-align: middle;
  box-sizing: border-box;
}

.impersonation {
  &__inputGroup {
    display: flex;
    flex-direction: column;

    label {
      margin: 0 0 0.25rem 0.25rem;
      line-height: 1rem;
    }
  }

  &__formElements {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 1.25rem 1rem;

    @include mixins.md_l {
      grid-template-columns: repeat(2, 1fr);
    }

    @include mixins.sm_xl {
      grid-template-columns: repeat(1, 1fr) !important;
      grid-template-rows: auto;
    }
  }

  &__inputNote {
    grid-column: span 2;

    @include mixins.sm_xl {
      grid-column: span 1;
    }
  }

  &__jwtToggle {
    display: flex;
    justify-items: flex-end;
    justify-content: flex-end;
  }

  &__disabled {
    color: colors.$disabled;
    border-color: colors.$disabled !important;
  }

  &__input {
    @extend %input;

    &:focus {
      outline: 0;
      border-color: colors.$dark_shade_cyan;
    }

    &:disabled {
      color: colors.$disabled;
      border-color: colors.$disabled !important;
    }
  }

  &__searchDropdown {
    all: unset;
    display: flex;
    flex-direction: column;
    width: 100%;

    .searchableDropdown {
      bottom: 0;
      margin-right: 0;

      input {
        outline: none;
        font-size: 1rem;
        padding: 0.75rem 0;
      }

      &__options-list {
        border-radius: 0.75rem;
      }
    }
  }

  &__inputSelect {
    @extend %input;

    width: 100%;
    appearance: none;
    background-image: url("../../common/icons/IconDropdownCarrot/svg/icon-dropdown-carrot.svg");
    background-repeat: no-repeat;
    background-position: right 0.5rem top 50%;
    background-size: auto;
  }

  &__inputSubmitGroup {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    grid-column: 3;
    gap: 0.5rem;

    @include mixins.md_l {
      grid-column: span 2;
    }

    @include mixins.sm_xl {
      grid-column: span 1;
    }
  }

  &__submitButton {
    display: flex;
    border-radius: 0.75rem;
    background-color: #38539a;
    font-size: 0.875rem;
    align-items: center;
    justify-content: center;
    border: 1px solid transparent;
    letter-spacing: 1px;
    padding: 0.5rem;
    gap: 0.5rem;
    width: 100%;

    span,
    img,
    path,
    svg {
      color: white;
      cursor: pointer;
    }

    @include mixins.md_l {
      width: 50%;
    }

    @include mixins.sm_xl {
      width: 100%;
    }

    .icon-user--white {
      cursor: pointer;

      svg {
        scale: 45%;
      }
    }

    &:disabled {
      color: colors.$disabled;
      border-color: colors.$disabled !important;
      background-color: colors.$disabled;
      cursor: not-allowed;

      span,
      img,
      path,
      svg {
        cursor: not-allowed;
      }
    }
  }

  &__container {
    padding: 1rem 1.5rem;
    margin: 0;
    text-align: center;

    // QR Code Modal
    .leaf-modal__modal-container {
      width: auto;
      height: auto;
    }

    .leaf-modal__modal-body {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 0;

      p {
        width: 90%;
        margin-bottom: 1rem;
      }
    }
  }

  h1 {
    font-size: 2rem;
    margin: 3rem 0 0.5rem;
  }

  &__form {
    max-width: 75rem;
    margin: 0 auto;
    text-align: left;
    padding-bottom: 1.25rem;
    border: none;
  }

  fieldset {
    border: none;
  }

  &__checkbox {
    padding-top: 1.875rem;
  }

  &__country-list {
    margin-top: 1rem;
    font-size: 0.9em;
    column-count: 1;
    padding: 1rem;
    text-align: left;
    border: solid 0.063rem #cbcbcb;
  }

  &__recently-used {
    padding: 1.25rem 0;
  }

  @media only screen and (width >= 25rem) {
    &__country-list {
      column-count: 2;
    }
  }

  @media only screen and (width >= 37.5rem) {
    &__country-list {
      column-count: 3;
    }
  }

  @media only screen and (width >= 50rem) {
    &__country-list {
      column-count: 4;
    }
  }

  @media only screen and (width >= 62.5rem) {
    &__country-list {
      column-count: 5;
    }
  }

  @media only screen and (width >= 75rem) {
    &__country-list {
      column-count: 6;
    }
  }

  body {
    margin: 0;
    text-align: center;
  }
}
