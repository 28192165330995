@use "../../common/scss/font";
@use "../../common/scss/colors";
@use "../../common/scss/mixins";

.App__component-section > div.cml > div.cml__header > h1 {
  padding-left: 0;
  padding-bottom: 1rem;
}

.cml {
  margin: 0 2.5rem;

  @include mixins.md_l {
    margin: 0 2rem;
  }

  @include mixins.sm_l {
    margin: 0 1rem;
  }

  &__header {
    .PageTitleSection {
      padding-left: 0;
      padding-right: 0;
    }
  }

  &__title {
    font-family: font.$family--primary;
    font-weight: font.$weight--bold;
    font-size: 2.625rem;
    align-items: center;
    padding: 1.5rem 0;
    width: 60%;
    overflow-wrap: normal;

    @include mixins.md {
      font-size: font.$size--large;
    }

    @include mixins.md_s {
      width: 100%;
    }
  }

  &__date {
    &--label {
      padding-right: 0.25rem;
    }

    &--header {
      text-align: right;
      display: flex;
      align-items: center;

      @include mixins.lg_s {
        display: flex;
        flex-direction: column;
        align-items: normal;
      }

      @include mixins.md_s {
        display: none;
      }
    }

    &--footer {
      display: none;
      text-align: center;
      padding: 0.5rem 0;

      @include mixins.md_s {
        display: flex;
        flex-direction: column;
        justify-content: center;
      }
    }
  }

  &__scroll {
    white-space: nowrap;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }

  &__scroll::-webkit-scrollbar {
    display: none;
  }

  &__nav {
    padding: 0.5rem 0;

    @include mixins.sm_l {
      padding: 0;
    }
  }

  .footnote {
    display: flex;
    flex-direction: row;
    padding: 0.75rem;
    font-size: font.$size--medium;
    line-height: 1.125rem;
    font-style: italic;
    color: colors.$founders_platinum_blue;
  }

  .Toastify__toast {
    @include mixins.more_than_desktop_inclusive {
      width: 24.5rem;
      height: auto;
      padding: 0.375rem 0.625rem;
    }
  }
}
