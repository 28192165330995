@use "../../../../common/scss/colors";
@use "../../../../common/scss/font";
@use "../../../../common/scss/mixins";

.server-side-export-menu {
  gap: 1.5rem;
  display: flex;
  flex-direction: column;

  .leaf-filter-button {
    font-size: font.$size--medium;
  }

  &__header {
    &__title {
      font-size: font.$size--big_s;
      font-weight: font.$weight--bold;
      line-height: 1.75rem;
    }

    &__subtitle {
      font-size: font.$size--medium;
      font-weight: font.$weight--regular;
      line-height: 1.125rem;
      color: colors.$dark_grey_on_white;
    }
  }

  &__footer {
    display: flex;
    flex-direction: column;
    gap: 0.75rem;
  }
}
