@use "../../scss/colors";
@use "../../scss/font";
@use "../../scss/mixins";

.leaf-2-cols-table {
  margin: 0.75rem 0;

  &__container {
    overflow: hidden;
    border-radius: 0.75rem;
    border: 0.063rem solid colors.$light_gray;

    &__table {
      width: 100%;
      border-collapse: collapse;

      &__row {
        height: 2.5rem;
      }

      &_header_td {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        max-width: 0.25rem;
      }

      &_content {
        font-weight: font.$weight--regular;
        font-family: font.$family--primary;
        font-size: font.$size--standard;
        padding: 0 1rem;
        justify-content: space-between;
        display: flex;
        align-items: center;

        @include mixins.under_md_ms {
          font-size: 0.875rem;
        }
      }

      &__default-title {
        background: colors.$amway_black;
        color: colors.$white;
        display: flex;
        padding: 0.5rem 1rem;
      }

      tr:nth-child(even) {
        background: colors.$amway_white;
      }
    }
  }
}
