@use "../../../scss/font";
@use "../../../scss/colors";
@use "../../../scss/mixins";

.leaf-filter-button {
  border: 0.125rem solid #2c2c2c;
  border-radius: 6.25rem;
  padding: 0.75rem 2rem;
  font-family: font.$family--header;
  font-weight: font.$weight--bold;
  font-size: font.$size--standard;
  background: colors.$white;
  height: 3rem;
  cursor: pointer;
  color: colors.$black;

  @include mixins.sm_l {
    height: 2.25rem;
    width: 50%;
    font-size: font.$size--medium;
    padding: 0.563rem 1.5rem;
  }

  &--not-active,
  &.dark.leaf-filter-button--not-active {
    cursor: not-allowed;
    background-color: colors.$amway_white;
    color: colors.$medium_gray;
    border: none;
  }
}

.dark {
  background: colors.$amway_black;
  color: colors.$white;
}
