.IconClipboard {
  mask-repeat: no-repeat;
  display: inline-block;
  user-select: none;
  fill: #6b7280;
  width: 0.8rem;

  &__white {
    fill: white;
  }

  &__blue {
    fill: #38539a;
  }

  &__green {
    fill: green;
  }

  svg {
    cursor: pointer;

    path {
      cursor: pointer;
    }
  }
}
