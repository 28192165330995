@use "../../../../common/scss/colors";
@use "../../../../common/scss/font";
@use "../../../../common/scss/mixins";

.server-side-export-download {
  display: flex;
  flex-direction: column;

  &__header {
    &__title {
      font-size: font.$size--big_s;
      font-weight: font.$weight--bold;
      line-height: 1.75rem;
      color: colors.$amway_black;
    }

    &__subtitle {
      color: colors.$dark_grey_on_white;
      font-size: font.$size--medium;
      font-weight: font.$weight--regular;
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    font-size: font.$size--medium;
    margin-top: 1.5rem;
  }

  &__no-content {
    font-size: font.$size--medium;
    color: colors.$error_red;
  }

  &__export-detail-loader {
    margin-bottom: 0.625rem;
  }

  &__export-detail {
    border-radius: 0.75rem;
    border: 0.0125rem solid colors.$light_gray;
    background: colors.$white;
    display: flex;
    gap: 0.5rem;
    padding: 1.125rem 1rem;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    align-self: stretch;
    font-weight: font.$weight--regular;
    margin-bottom: 0.625rem;
  }

  &__export-type {
    color: colors.$amway_black;
    font-size: font.$size--standard;
    font-weight: font.$weight--medium;
  }

  &__export-period {
    font-weight: font.$weight--bold;
  }

  &__divider-line {
    width: 100%;
    border: 0.063rem solid colors.$light_gray;
  }

  &__icons {
    display: flex;
    gap: 0.5rem;
  }

  &__download-icons {
    display: none;

    &--show {
      display: flex;
      gap: 0.5rem;
    }
  }

  &__icon-divider {
    display: block;
    border: 0.063rem solid colors.$light_gray;
  }

  &__start-time {
    font-size: font.$size--medium;
    font-weight: font.$weight--regular;
    text-align: left;
    color: colors.$dark_grey_on_white;
  }

  &__footer {
    button {
      width: 100%;
      height: 2.25rem;
      margin-top: 1.5rem;
      text-align: center;
      font-size: font.$size--standard;
      padding: 0.313rem;
      font-weight: font.$weight--bold;
    }
  }

  &__export-status {
    display: flex;
  }

  &__status-message {
    &--ERROR {
      font-weight: font.$weight--bold;
      color: colors.$error_red;
    }

    &--IN_PROGRESS,
    &--PENDING {
      font-weight: font.$weight--bold;
      color: colors.$amway_black;
    }

    &--DONE {
      color: colors.$light_green;
    }
  }

  &__ready {
    display: none;

    &--DONE {
      display: block;
      font-weight: font.$weight--bold;
      padding-right: 0.25rem;
      color: colors.$light_green;
    }
  }

  &__expiryMessage {
    font-weight: font.$weight--medium;
  }

  &__icon svg circle {
    cursor: pointer;
  }
}
