@use "../../../common/scss/font";
@use "../../../common/scss/colors";

.menu-items {
  display: inline;
  line-height: 0.75rem;
  position: relative;
  white-space: nowrap;

  a,
  button {
    font-size: font.$size--medium;
    padding: 0.438rem 0;
    text-align: left;
    cursor: pointer;
    width: 100%;
  }

  a {
    display: flex;
    line-height: 1.125rem;
    padding: 0.375rem;
    justify-content: left;
    align-items: center;
    text-decoration: none;
  }

  button {
    display: flex;
    background-color: transparent;
    border: none;
    color: inherit;
    cursor: pointer;
    justify-content: center;
    transition: font-weight 0.2s ease, background-color 0.2s ease;
    white-space: nowrap;

    &:hover,
    &.bold {
      font-weight: font.$weight--bold;
    }
  }

  & > a,
  & > a:hover {
    background-color: transparent;
    display: flex;
    justify-content: center;
  }

  a:hover {
    background-color: colors.$light_purple;
  }

  &__active,
  &__active > * {
    font-weight: font.$weight--bold;
    color: colors.$blue;
  }
}

#academy_httpswwwqaacademypreprodamwaylivecomlogin:hover,
#academy_httpswwwqaanaacademypreprodamwaycomlogin:hover,
#academy_httpswwwqavnacademypreprodamwaycomlogin:hover {
  font-weight: bold;
  background-color: inherit;
}

// prevents layout shift when menu-item is active
.menu-items::after {
  content: attr(data-text);
  font-size: font.$size--medium;
  padding: 0 0.75rem;
  height: 0;
  visibility: hidden;
  overflow: hidden;
  user-select: none;
  pointer-events: none;
  font-weight: font.$weight--bold;
}
