@use "../../scss/colors";
@use "../../scss/font";
@use "../../scss/layers";

.leaf-tooltip {
  &__tooltip {
    background-color: colors.$blue;
    border-radius: 0.75rem;
    font-size: font.$size--medium;
    font-family: font.$family--primary;
    opacity: 1;
    width: fit-content;
    text-align: left;
    z-index: layers.$content;
  }
}
