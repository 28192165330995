@use "../../../../common/scss/mixins";
@use "../../../../common/scss/colors";
@use "../../../../common/scss/font";

.TpiTile {
  width: 100%;

  &__tiles {
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: space-between;
    gap: 1rem;
    width: 100%;

    &--rectangle-loader {
      width: 100%;
      flex-direction: column;
    }

    &--tile {
      display: flex;
      width: 100%;
      gap: 1rem;
      flex: 1;

      @include mixins.lg_s {
        font-size: font.$size--medium !important;
      }

      .leaf-tile {
        padding: 0.75rem 1rem;
      }

      .desc-only {
        flex: 1;
      }
    }

    &--row {
      display: flex;
      justify-content: space-between;
      width: 100%;
      gap: 1em;

      @include mixins.less_than_tablet {
        flex-direction: column;
      }
    }
  }
}
