@use "../../../common/scss/mixins";

.report-export-modal {
  .leaf-modal {
    &__modal-container {
      padding-bottom: 2rem;
      max-width: 21.45rem;
    }

    &__modal-body {
      padding-bottom: 0;
    }
  }

  @include mixins.md_s {
    .leaf-modal__modal-container {
      height: auto;
    }
  }

  @include mixins.sm_l {
    .leaf-filter-button {
      width: 100%;
    }
  }
}
