@use "../../../scss/font";
@use "../../../scss/colors";

.leaf-abo-address {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: font.$size--medium;
  gap: 1rem;

  &__label {
    font-weight: font.$weight--medium;
  }

  &__address {
    font-weight: font.$weight--regular;
    line-height: font.$size--big;
    color: colors.$dark_grey_on_white;
    word-break: break-word;
  }

  &__icon {
    &--copy {
      background: none;
      border: none;
    }
  }
}
