@use "../../scss/colors";

%icon-user-placeholder {
  mask-repeat: no-repeat;
  display: inline-block;
  height: 2.0625rem;
  width: 1.75rem;
  user-select: none;
}

.icon-user {
  @extend %icon-user-placeholder;

  &--gray {
    @extend %icon-user-placeholder;

    svg,
    path {
      fill: colors.$light_gray_accent;
    }
  }

  &--orange {
    @extend %icon-user-placeholder;

    svg,
    path {
      fill: colors.$data_viz_orange;
    }
  }

  &--green {
    @extend %icon-user-placeholder;

    svg,
    path {
      fill: colors.$light_green;
    }
  }

  &--white {
    @extend %icon-user-placeholder;

    svg,
    path {
      fill: colors.$white;
    }
  }
}
