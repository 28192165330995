@use "../../../common/scss/colors";
@use "../../../common/scss/font";
@use "../../../common/scss/mixins";

.Apd {
  width: 100%;
  padding: 0;
  display: flex;

  &__heading {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 0 1rem;
    padding-top: 2rem;
    padding-bottom: 1rem;
    width: 100%;

    @include mixins.more_than_desktop_inclusive {
      padding: 0 1rem;
      padding-top: 1.5rem;
      padding-bottom: 1rem;
    }

    h1 {
      padding: 0;
      padding-right: 0.5rem;
    }

    button {
      border: none;
      background-color: colors.$white;

      :hover {
        cursor: pointer;
      }
    }

    &--title {
      display: flex;
      justify-items: flex-start;
      flex-direction: row;
    }

    &--timestamp {
      color: colors.$dark_grey_on_white;
      display: flex;
      align-items: center;

      @include mixins.less_than_desktop_inclusive {
        display: none;
      }
    }
  }

  &__container,
  &__container-split {
    display: flex;
    flex-direction: column;
    width: 100%;

    @include mixins.more_than_desktop_inclusive {
      flex: 1;
      align-items: flex-start;
    }
  }

  &__container-row {
    display: flex;
    flex-direction: column;
    width: 100%;

    @include mixins.over_lg_s {
      flex: 1;
      align-items: flex-start;
      flex-direction: row;
    }
  }

  &__container-split {
    padding: 0.5rem 0;

    @include mixins.more_than_desktop_inclusive {
      flex-direction: column;
    }

    @include mixins.tablet_only {
      overflow-x: hidden;
    }

    @include mixins.sm_xl {
      overflow: hidden;
    }
  }

  &__section {
    padding: 0 1rem;
    width: 100%;
    flex: 1;
    height: 100%;

    &-add-space {
      padding-top: 1.5rem;

      @include mixins.tablet_only {
        padding-top: 0;
      }

      @include mixins.md {
        padding-top: 0;
      }

      @include mixins.sm_xl {
        padding-top: 0;
      }
    }

    &-title {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 1rem;

      h2 {
        padding: 0;
        margin: 0;
        font-size: 1.125rem;

        @include mixins.more_than_desktop_inclusive {
          font-size: 1.5rem;
        }
      }
    }

    .leaf-tile--no-border {
      padding-right: 0;
      padding-left: 0;
    }
  }

  &__footer {
    width: 100%;
    margin-bottom: 2rem;
  }

  &__keymetricsContainer {
    margin-top: -1rem;

    & > .leaf-tile:first-of-type {
      padding: 0;
    }

    &__title {
      padding: 1rem 0;
    }

    &__gear {
      border: none;
      background-color: colors.$white;
    }

    &__header {
      display: flex;
      justify-content: space-between;
    }
  }

  &__link {
    font-size: font.$size--standard;
    color: colors.$blue;
    font-weight: font.$weight--bold;
    cursor: pointer;
    background-color: transparent;
    border: none;
    line-height: font.$size--large;
  }
}

.KeyMetrics__container {
  display: grid;

  @include mixins.from_tablet {
    grid-template-columns: repeat(2, 1fr) !important;
    grid-template-rows: auto !important;
  }
}

.Bbt {
  &__section-title {
    margin-bottom: 0.2rem;

    h2 {
      font-size: font.$size--large;
    }

    @include mixins.less_than_desktop_inclusive {
      margin-bottom: 0;
    }
  }

  &__row,
  &__section-subtitle,
  &__total {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 0.6rem;
    padding: 0.67rem 0;
    line-height: font.$size--huge;
  }

  &__section-subtitle {
    font-size: font.$size--standard;
    font-weight: bold;
    padding: 0.8rem 0;
  }

  &__section-info {
    padding: 0.5rem 0;
    color: colors.$dark_grey_on_white;
    display: flex;
    align-items: center;

    @include mixins.less_than_desktop_inclusive {
      display: none;
    }
  }

  &__total-amount {
    font-size: font.$size--large;

    @include mixins.less_than_tablet {
      font-size: font.$size--standard;
    }

    @include mixins.sm_l {
      font-size: font.$size--medium;
    }
  }

  &__total {
    font-size: font.$size--big;
    font-weight: bold;
    padding-bottom: 0;

    @include mixins.less_than_tablet {
      font-size: font.$size--standard;
    }

    @include mixins.sm_l {
      font-size: font.$size--medium;
    }
  }

  &__row {
    font-size: font.$size--standard;
    padding-left: 1.25rem;
    padding-right: 1.25rem;

    a:link,
    a:visited {
      font-weight: bold;
      color: colors.$blue;
      text-decoration: underline;
      text-underline-offset: 0.3rem;
      text-decoration-thickness: 2px;
    }

    .icon-subsection {
      margin-left: 1.4rem;
      width: 1.5rem;
      background-position-y: 2px;
    }

    @include mixins.less_than_desktop_inclusive {
      padding: 0.4rem 0.75rem;
    }

    @include mixins.less_than_tablet {
      font-size: font.$size--medium;
    }
  }

  &__dark {
    background-color: colors.$amway_white;
  }

  &__bold {
    font-weight: bold;
  }
}

.TpiTile {
  width: 100%;

  &__title {
    padding: 1rem 0;
  }

  &__header {
    display: flex;
    justify-content: space-between;
  }

  &__large-tile {
    padding-top: 1rem;
  }
}
