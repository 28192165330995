@use "../../../../scss/colors";
@use "../../../../scss/font";

.leaf-applicant-carousel {
  &__header {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    align-self: stretch;
    padding-bottom: 1rem;

    &__title {
      flex: 1 0 0;
      font-weight: font.$weight--bold;
      font-size: font.$size--standard;
      line-height: font.$size--large;
    }

    &__view-all {
      line-height: 1.125rem;
    }
  }

  &__body {
    &__card {
      height: 100%;
      display: flex;
      padding: 1rem;
      flex-direction: column;
      border: 0.063rem solid colors.$light_gray;
      border-radius: 0.75rem;
      align-self: stretch;
      background-color: colors.$white;
    }
  }

  .slick-track {
    display: flex;
    flex-direction: row;
    height: 100%;
  }

  .slick-list,
  .slick-slide {
    height: 100%; /* Ensure all elements fill available space */
  }

  .slick-slide > div {
    margin: 0 0.25rem;
    height: 100%;
  }

  .slick-list {
    margin: 0 -0.25rem;
  }
}
