$height: 1.375rem;
$width: 1.3125rem;
$scale_lg: calc(2.3rem / $height);
$scale_xl: calc(6.3rem / $height);
%iconBase {
  height: $height;
  width: $width;
  display: block;
  background-repeat: no-repeat !important;
}

.icon-shield {
  &--xl {
    height: $scale_xl * $height; // ~ 138px
    width: $scale_xl * $width; // ~ 132px
    background-size: calc($scale_xl * $height) calc($scale_xl * $width) !important;
    display: block;
  }

  &--large {
    height: $scale_lg * $height; // ~ 36px
    width: $scale_lg * $width; // ~ 35px
    background-size: calc($scale_lg * $height) calc($scale_lg * $width) !important;
  }

  &--medium-large {
    scale: 160%;
  }

  &--extra-small {
    scale: 75%;
  }

  &--small {
    scale: 100%;
  }

  &--medium {
    scale: 110%;
  }
}
