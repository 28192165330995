@use "../../scss/colors";

%icon-refresh {
  display: block;
  height: 1rem;
  width: 1rem;
}

.icon-refresh {
  @extend %icon-refresh;

  &--white {
    @extend %icon-refresh;

    svg,
    path {
      fill: colors.$white;
    }
  }

  &--black {
    @extend %icon-refresh;

    svg,
    path {
      fill: colors.$black;
    }
  }

  &--blue {
    @extend %icon-refresh;

    svg,
    path {
      fill: colors.$blue;
    }
  }
}
