@use "../../scss/colors";
@use "../../scss/layers";
@use "../../scss/mixins";

.KeyMetricsTile {
  position: relative;

  &__backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: transparent;
    z-index: layers.$backdrop;
  }

  &__gear {
    border: none;
    background-color: colors.$white;
  }

  &__gear > * {
    cursor: pointer;
  }

  &__header {
    display: flex;
    justify-content: space-between;
    padding-bottom: 1rem;
  }

  .leaf-panel__closeBtn {
    margin: 0.5rem 0.3rem 0 0;

    @include mixins.md_s {
      margin: 0;
    }
  }
}
