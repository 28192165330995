@use "../../../../common/scss/colors";

.abo-applicant-body {
  display: flex;
  flex-direction: column;
  gap: 0.625rem;

  &__card {
    display: flex;
    padding: 1rem;
    flex-direction: column;
    border-radius: 0.75rem;
    border: 1px solid colors.$light_gray;
    background: colors.$white;
  }
}
