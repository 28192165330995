@use "../../scss/colors";
@use "../../scss/font";

.leaf-card {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 1rem;
  gap: 1rem;
  margin: 1rem;
  border: 0.0625rem solid colors.$light_gray;
  border-radius: 0.75rem;
  height: 100%;
  transition: border 100ms ease-out;
  animation-timing-function: ease-out;

  &__info {
    display: flex;
    flex-direction: column;
    flex-grow: 1;

    &-title {
      display: flex;
      font-family: font.$family--primary;
      font-style: normal;
      font-weight: font.$weight--medium;
      font-size: font.$size--medium;
      line-height: 1.125rem;
      color: colors.$dark_grey_on_white;
      margin-bottom: 0.25rem;
    }

    &-content {
      display: flex;
      font-family: font.$family--primary;
      font-style: normal;
      font-weight: font.$weight--bold;
      font-size: font.$size--large;
      line-height: 1.75rem;
      color: colors.$amway_black;
    }
  }

  &__navigation {
    display: flex;
    flex-direction: column;

    &-arrow {
      float: right;
      text-align: right;
      font-size: font.$size--medium;
      line-height: 1.125rem;
    }
  }
}

.leaf-card:hover {
  background-color: colors.$light_purple;
}
