@use "../../common/scss/colors";

.JwtImpersonation {
  &__progress,
  &__bar {
    height: 1cm;
    border-radius: 0.5cm;
  }

  &__progress {
    width: 80%;
    background-color: colors.$light_gray;
    border: solid 0.0625rem colors.$disabled;
    margin: 0 10%;
    overflow: hidden;
    position: absolute;
    top: 50%;
  }

  &__bar {
    width: 0%;
    background-color: colors.$blue;
    transition: width 0.5s;
  }
}
