@use "../../scss/colors";
@use "../../scss/font";

.leaf-badge-count {
  display: flex;
  flex-direction: column;
  padding: 0.063rem 0.0375rem 0 0;
  width: 1.25rem;
  border-radius: 50%;
  background: colors.$blue;

  &__text {
    font-family: font.$family--primary;
    font-style: normal;
    font-weight: font.$weight--bold;
    font-size: font.$size--medium;
    line-height: 1.125rem;
    text-align: center;
    color: colors.$white;
  }
}
