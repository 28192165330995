.LeafIconButton {
  &__button {
    border: none;
    background: none !important;
    display: flex;
    align-self: center;
    cursor: pointer;
    width: auto;

    &:hover {
      transition: all 2s ease-in-out;
      cursor: pointer !important;

      svg,
      path,
      rect {
        cursor: pointer !important;
      }
    }
  }
}
