@use "../../scss/colors";

.icon-star {
  &__green {
    svg > * {
      fill: colors.$blue;
    }
  }

  &__gray path {
    fill: colors.$light_gray;
  }

  &--sm {
    display: flex;

    svg {
      height: 0.75rem;
      width: 0.8125rem;
    }
  }

  &--md {
    display: flex;

    svg {
      height: 0.9281rem;
      width: 1rem;
    }
  }

  &--lg {
    display: flex;

    svg {
      height: 2.5rem;
      width: 2.5rem;
    }
  }
}
