@use "../../../../common/scss/font";
@use "../../../../common/scss/mixins";

.Mpd-Tile {
  &__Gpv-value {
    font-size: 1.5rem;
    font-weight: font.$weight--bold;

    @include mixins.more_than_desktop_inclusive {
      font-size: font.$size--large_l;
    }
  }
}

.hidden {
  display: none;
}
