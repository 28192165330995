@use "../../scss/colors";
@use "../../scss/font";
@use "../../scss/layers";

.leaf-dropdown {
  display: flex;
  flex-direction: column;

  &--cursorPointer {
    * {
      cursor: pointer !important;
    }
  }

  &__list {
    position: absolute;
    top: 3rem;
    right: 1.9rem;
    z-index: layers.$dropdown;
    display: inline-flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 0.25rem;
    background-color: colors.$white;
    border-radius: 0.75rem;
    box-shadow: 0 2px 16px 0 rgb(44 44 44 / 10%);
    list-style: none;
    padding: 0.1rem;
    scroll-behavior: smooth;
    max-height: calc(100% - 4rem);
    overflow: hidden auto;
    scroll-snap-type: y mandatory;
  }

  &__pointer-triangle::after {
    border-bottom: 0.9rem solid colors.$white;
    border-left: 0.9rem solid transparent;
    border-right: 0.9rem solid transparent;
    border-start-start-radius: 75%;
    content: "";
    left: 45%;
    position: absolute;
    right: auto;
    top: -0.813rem;
  }

  &__item {
    display: flex;
    width: 100%;
    padding: 0.5rem 0.75rem;
    align-items: center;
    gap: 0.3rem;
    color: colors.$amway_black;
    font-size: font.$size--medium;
    font-weight: font.$weight--regular;
  }

  &__item:hover {
    color: #38539a;
    background-color: #f1f2ff;
  }

  &__button {
    background-color: transparent;
    border: none;
    display: flex;
    justify-content: space-between;
    width: 100%;
    min-width: 10rem;
    padding: 0.5rem 0.75rem 0;
    align-items: center;
    gap: 0.3rem;
    color: colors.$amway_black;
    font-size: font.$size--medium;

    &:hover {
      color: colors.$blue;
    }

    &__selected-option {
      color: colors.$blue;
    }
  }

  &__button-no-select {
    cursor: none;
  }

  &__firstItem {
    border-radius: 0.75rem 0.75rem 0 0;
  }

  &__lastItem {
    border-radius: 0 0 0.75rem 0.75rem;
  }
}

.selected {
  color: colors.$blue;
  font-weight: font.$weight--semibold;
}

.disabled {
  color: #5e5e5e;
  background-color: #d9d9d9;
  cursor: default;
}

.disabled > p {
  cursor: default;
}

.disabled:hover {
  color: #5e5e5e;
  background-color: #d9d9d9;
}
