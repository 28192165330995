@use "sass:color";
@use "../../common/scss/colors";
@use "../../common/scss/mixins";

.error {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0.5rem 3rem;
  height: fit-content;

  &__no-header {
    height: 100% !important;
  }

  &__icon {
    display: flex;
    justify-content: center;
  }

  &__buttons {
    display: flex;
    justify-content: center;

    .LeafButton {
      width: auto;
      padding: 0 1rem;
      min-width: 15rem;
      margin: 2rem 0;
      display: flex;
      gap: 0.5rem;
    }
  }

  &__title {
    flex-wrap: wrap;
    font-size: 3rem;
    font-weight: 700;
    margin: 1rem auto;
    max-width: 60rem;
    text-align: center;

    @include mixins.md_l {
      font-size: 2.6rem;
    }

    @include mixins.md_s {
      font-size: 2rem;
    }

    @include mixins.sm_l {
      font-size: 1.6rem;
    }
  }

  &__message {
    flex-wrap: wrap;
    font-size: 1.6rem;
    margin: 1rem auto;
    max-width: 40rem;
    text-align: center;
  }

  &__button {
    border-radius: 2.4rem;
    color: colors.$white;
    background-color: colors.$amway_black;
    border-style: none;
    min-height: 4.5rem;
    padding: 1rem 3rem;
    margin: 1rem 0;

    & > * {
      text-align: center;
      font-size: 1.6rem;
    }
  }

  &__button:hover {
    cursor: pointer;
  }

  &__button:active {
    background-color: color.adjust(colors.$amway_black, $lightness: 15%);
  }

  &__browserIcons {
    margin-top: 3rem;
    flex-direction: row;
    display: flex;
    justify-content: center;
    column-gap: 1.75rem;

    a {
      display: flex;
      width: 2rem;
      height: 2rem;
    }
  }
}
