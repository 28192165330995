@use "../../../../scss/font";

.leaf-abo-name {
  &--normal {
    font-size: font.$size--standard;
  }

  &--big {
    font-size: font.$size--big;
  }

  &--huge {
    font-size: font.$size--huge;
  }

  &--large {
    font-size: font.$size--large;
  }

  &--medium {
    font-size: font.$size--medium;
    font-weight: font.$weight--medium;
  }

  &--small {
    font-size: font.$size--small;
  }

  &--thin {
    font-weight: font.$weight--thin;
  }

  &--regular {
    font-weight: font.$weight--regular;
  }

  &--semibold {
    font-weight: font.$weight--semibold;
  }

  &--bold {
    font-weight: font.$weight--bold;
  }
}
