@use "../../scss/mixins";

.Icon-Lock {
  padding-top: 0.5rem;

  svg {
    width: 18.329rem;
    height: 12.5rem;

    @include mixins.md_s {
      width: 12.464rem !important;
      height: 8.5rem !important;
    }
  }
}
