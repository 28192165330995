@use "../../../../../common/scss/colors";

.abo-applicant-card {
  display: flex;
  flex-direction: column;
  padding: 1rem;
  margin-top: 1rem;
  border: 0.063rem solid colors.$light_gray;
  border-radius: 0.75rem;
  align-self: stretch;
  background-color: colors.$white;
}
