@use "../../scss/colors";

.icon-checkCircle {
  &__green {
    svg > * {
      fill: colors.$light_green;
    }
  }

  &__blue {
    svg > * {
      fill: colors.$dark_blue;
    }
  }

  &__gray path {
    fill: colors.$light_gray;
  }

  &--xs {
    display: flex;

    svg {
      height: 0.75rem;
      width: 0.75rem;
    }
  }

  &--sm {
    display: flex;

    svg {
      height: 1rem;
      width: 1rem;
    }
  }

  &--md {
    display: flex;

    svg {
      height: 1.5rem;
      width: 1.5rem;
    }
  }

  &--lg {
    display: flex;

    svg {
      height: 2.5rem;
      width: 2.5rem;
    }
  }

  &--xlg {
    display: flex;

    svg {
      height: 3rem;
      width: 3rem;
    }
  }
}
