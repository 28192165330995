@use "../../scss/colors";

.WheelPicker {
  padding: 0.625rem 1.25rem;

  &__wheel {
    flex-direction: row;
    display: flex;
    box-sizing: border-box;
    flex: 1;
    width: 100%;
  }

  &__selected-row {
    width: calc(100% - 2.5rem);
    box-sizing: border-box;
    height: 2.5rem;
    background: colors.$light_purple;
    position: absolute;
    border-radius: 0.75rem;
    transform: translateY(-7.5rem);
  }
}
