@use "../../../common/scss/font";
@use "../../../common/scss/mixins";

.business-selector-toast-container {
  .Toastify__toast {
    min-height: 0.625rem;

    @include mixins.sm_m {
      width: 19.5rem;
      font-size: font.$size--small;
    }

    @include mixins.sm_s {
      width: 17rem;
    }
  }
}

.business-selector-toast {
  display: flex;
  align-items: center;
  height: 0;
  font-weight: font.$weight--bold;
}
