@use "../../../common/scss/colors";
@use "../../../common/scss/font";
@use "../../../common/scss/layers";

.dropdown {
  background-color: colors.$white;
  border-radius: 0.313rem;
  display: none;
  font-size: font.$size--medium;
  list-style: none;
  padding-top: 0.938rem;
  position: absolute;
  z-index: layers.$dropdown;

  &__show {
    box-shadow: 0 0.125rem 1rem colors.$light_gray;
    display: block;
    margin-top: 1rem;
    min-width: 15.625rem;
    padding-bottom: 0.625rem;
  }

  &__show::after {
    border-bottom: 1.563rem solid colors.$white;
    border-left: 1.563rem solid transparent;
    border-right: 1.563rem solid transparent;
    content: "";
    left: 10%;
    position: absolute;
    right: auto;
    top: -0.813rem;
  }

  &__menu-item {
    font-weight: font.$weight--regular;
    padding: 0 0.406rem;
  }

  &__active,
  &__active a:hover {
    font-weight: font.$weight--bold !important;
  }

  &__hide {
    display: none;
  }
}
