@use "../../scss/colors";
@use "../../scss/mixins";
@use "../../scss/font";

.Table-container {
  margin-bottom: 3rem;
}

.Table {
  width: 100%;
  border-collapse: collapse;
  min-height: 3rem;

  th,
  td {
    text-align: left;
    min-width: 100px;
    width: 25%;
  }

  th:first-child,
  td:first-child {
    padding-left: 1rem;
  }

  &__container {
    width: 100%;
    border-top-left-radius: 0.75rem;
    border-top-right-radius: 0.75rem;
    overflow-x: auto;
  }

  &__search-and-filter {
    display: flex;
    gap: 1rem;
    width: 18rem;
    margin: 0.5rem 0 0.5rem 0.5rem;
  }

  &__searchInput {
    width: 100%;
    padding: 0.4rem;
    border-radius: 0.5rem;
    border: 1px solid colors.$amway_black;
  }

  &__filter {
    display: flex;
    gap: 0.25rem;
  }

  &__header {
    background: colors.$amway_black;
    color: colors.$white;
    font-weight: font.$weight--bold;
    font-size: font.$size--standard;
    height: 3rem;

    &__hidden {
      display: none;
    }

    @include mixins.md {
      font-size: font.$size--medium;
      padding: 1rem 0.5rem;
      word-break: break-word;
    }
  }

  &__bodyContainer {
    display: block;
    max-height: 26rem;
    overflow: hidden auto;
    width: 100%;
  }

  &__row {
    height: 3rem;
    font-size: font.$size--standard;

    @include mixins.md {
      font-size: font.$size--medium;
    }
  }

  &__row:nth-child(odd) {
    background: colors.$amway_white;
  }

  &__row:hover {
    background: colors.$light_blue;
  }

  &__noResults {
    text-align: center !important;
    font-weight: font.$weight--bold;
    font-size: font.$size--standard;
  }
}

.filterMenu {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 1rem;
  overflow: auto;

  &__button {
    padding: 0.25rem;
    background: white;
    border: none;
    cursor: pointer;
    font-size: font.$size--standard;

    &--active {
      background: colors.$light_purple;
      border: 0.0625rem solid colors.$blue;
      border-radius: 0.5rem;
      color: colors.$blue;
    }
  }

  &__button:hover {
    background: colors.$light_purple;
    border-radius: 0.5rem;
  }

  &__footer {
    display: flex;
    justify-content: flex-end;
    margin-top: 1rem;
    gap: 1rem;

    &__button {
      background: white;
      border: 2px solid colors.$amway_black;
      padding: 0.4rem;
      border-radius: 0.5rem;
      cursor: pointer;
      font-size: 1rem;
    }
  }
}
