@use "../../scss/mixins";

%toast {
  padding: 0.5rem;
  border-radius: 0.5rem;
  margin: 0.2rem;
  border: solid 1px;
  display: flex;
}

.leafBannerToast {
  $theme_colors: ("green", "red", "yellow", "blue", "dark");

  @each $color in $theme_colors {
    &__#{$color} {
      @extend %toast;
      @include mixins.banner_theme($color);
    }
  }

  &__closeButton {
    font-size: 1.5rem;
    line-height: 1.375rem;
    height: 1.5rem;
    cursor: pointer;
    padding-top: 0.188rem;
  }

  &__message {
    flex: 1;
  }
}
