@use "../../../common/scss/colors";
@use "../../../common/scss/font";
@use "../../../common/scss/mixins";

.abo-profile-slider {
  &__timestamp {
    margin-top: 2.5rem;
    margin-bottom: 1rem;
    justify-content: center;

    &--desktop {
      display: flex;

      @include mixins.less_than_desktop_inclusive {
        display: none;
      }
    }

    &--mobile {
      display: none;

      @include mixins.less_than_desktop_inclusive {
        display: flex;
      }
    }
  }

  .TimeStamp {
    font-size: font.$size--medium;
    font-weight: font.$weight--regular;
  }
}
