@use "../../../../common/scss/colors";
@use "../../../../common/scss/mixins";
@use "../../../../common/scss/font";

%impersonationButton {
  cursor: pointer;
  box-sizing: border-box;
  border: none;
  height: 2rem;
  width: 2rem;
  padding: 1rem;
  display: flex;
  place-content: center;
  place-items: center;
  border-radius: 0.5rem;
}

.ImpersonationTable {
  width: 100%;
  border-collapse: collapse;
  table-layout: fixed;

  th {
    padding-left: 1rem;
    text-align: left;
  }

  &__container {
    display: table;
    width: 100%;
    border-top-left-radius: 0.75rem;
    border-top-right-radius: 0.75rem;
    overflow: hidden;
  }

  &__header {
    background: colors.$amway_black;
    color: colors.$white;
    font-weight: font.$weight--bold;
    font-size: font.$size--standard;
    height: 3rem;

    &__hidden {
      display: none;
    }

    @include mixins.md {
      font-size: font.$size--medium;
      padding: 1rem 0.5rem;
      word-break: break-word;
    }
  }

  &__bodyContainer {
    max-height: 26rem;
    overflow: auto;
  }

  &__row {
    height: 3rem;
    font-size: font.$size--standard;
    transition: background-color 0.2s ease;

    @include mixins.md {
      font-size: font.$size--medium;
    }
  }

  &__row:nth-child(odd) {
    background: colors.$amway_white;
  }

  &__row:hover {
    background: colors.$light_blue;
  }

  &__filterTagItem {
    display: flex;
    flex-direction: row;
    gap: 0.5rem;
    align-items: center;
  }

  &__rowItem {
    display: flex;
    flex-direction: row;
    gap: 0.5rem;
    align-items: center;
    margin-left: 1rem;
    overflow: hidden;
    text-overflow: ellipsis;

    @include mixins.md {
      margin-left: 0.5rem;
    }
  }

  &__buttonGroup {
    display: flex;
    justify-items: flex-end;
    justify-content: flex-end;
    padding-right: 1rem;
    gap: 1rem;
  }

  &__deleteButton {
    @extend %impersonationButton;

    background: #d9534f;
    padding: 0.5rem;

    svg {
      cursor: pointer;
      display: flex;
      width: 2rem;

      path {
        fill: white;
      }
    }
  }

  &__impersonateButton {
    @extend %impersonationButton;

    background: colors.$blue;

    .icon-user--white {
      cursor: pointer;

      svg {
        scale: 50%;
        cursor: pointer;
      }
    }
  }

  &__impersonateButton,
  &__deleteButton {
    transition: transform 0.2s ease, opacity 0.2s ease;

    &:hover {
      transform: scale(1.05);
      opacity: 0.9;
    }

    &:focus {
      outline: 2px solid colors.$light_blue;
    }
  }
}

@include mixins.sm {
  .ImpersonationTable {
    &__bodyContainer {
      max-height: 20rem;
    }

    &__buttonGroup {
      gap: 0.5rem;
    }
  }
}
