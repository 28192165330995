@keyframes rotate {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.icon-loading-circle {
  width: 2.25rem;
  height: 2.25rem;
  display: flex;
  align-items: center;
  justify-content: center;

  &__loader {
    transform-origin: center;
    animation: rotate 2s linear infinite;
  }
}
