@use "../../common/scss/colors";
@use "../../common/scss/font";
@use "../../common/scss/mixins";

.actionReports {
  &__report-list {
    list-style: none;
    margin: 0.75rem 0.25rem 1rem 2.5rem;

    @include mixins.md_l {
      margin-left: 0.75rem;
    }
  }
}

.actionReportsItem {
  display: flex;
  flex-direction: column;
  padding: 0 0 0 0.5rem;

  &__header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-right: 1.25rem;
  }

  &__title {
    display: inline-flex;
    font-size: font.$size--large;
    font-weight: 700;
    padding: 0 0.625rem 0.25rem 0;
    font-family: font.$font-stack-source !important;
  }

  &__description {
    font-size: 1rem;
    min-height: 1.25rem;
    height: 100%;
    font-family: font.$font-stack-source !important;
    width: 80%;
  }

  &__button {
    background: transparent;
    border: none;
    display: flex;
    flex-direction: row;
    place-items: center center;
    font-size: 1rem;
    font-family: "Source Sans Pro", sans-serif !important;
    font-weight: 700;
    column-gap: 0.5rem;
  }

  &__link {
    height: 100%;
    box-sizing: border-box;
    padding: 0.875rem 0;
    border-bottom: 1px solid colors.$light_gray_bg;
  }
}

.actionReportsItem:hover {
  background-color: colors.$light_blue;
  cursor: pointer;
}

.actionReportWrapper {
  min-height: 46rem;
}
