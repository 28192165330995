@use "../../../scss/colors";
@use "../../../scss/font";

.abo-email {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: font.$size--medium;
  gap: 1rem;

  &__label {
    font-weight: font.$weight--medium;
  }

  &__address {
    font-weight: font.$weight--regular;
    line-height: font.$size--big;
    color: colors.$dark_grey_on_white;
    word-break: break-word;
  }
}
