@use "../../scss/colors";

.leaf-tile {
  min-width: 13.75rem;
  min-height: 12rem;
  height: 100%;
  border-radius: 0.75rem;
  border: 1px solid colors.$light_gray;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  background-color: colors.$white;

  &--no-border {
    border: none;
  }

  &--gray {
    background-color: colors.$very_light_gray;
  }

  &__title {
    font-size: 1.5rem;
    display: flex;
    margin-bottom: 1rem;
    align-items: center;

    span {
      margin-right: 0.5rem;
    }

    &--hidden {
      display: none;
    }
  }

  &__children {
    height: 100%;
    width: 100%;
  }

  &__description {
    font-size: 1rem;
    line-height: 1.5rem;
  }

  &--hidden {
    display: none;
  }
}

.desc-only {
  min-height: auto;
}
