@use "../../../scss/colors";

.rectangle-loader {
  &__container {
    display: grid;
    gap: 1rem;
  }

  background: linear-gradient(
    90deg,
    colors.$light_gray 0.19%,
    rgb(228 228 228 / 82.8%) 21.92%,
    rgb(228 228 228 / 0%) 100.19%
  );
  background-size: 150% 400%;
  border-radius: 0.5rem;
  animation: gradient 2s ease infinite;
  margin-bottom: 1rem;
}

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }

  50% {
    background-position: 100% 50%;
  }

  100% {
    background-position: 0% 50%;
  }
}
