@use "../../../../scss/font";
@use "../../../../scss/colors";

.leaf-applicant-item {
  &__temp-data {
    font-family: font.$family--primary;
    font-style: normal;
    font-size: font.$size--medium;

    &--label {
      font-weight: font.$weight--medium;
    }

    &--details {
      font-weight: font.$weight--regular;
      color: colors.$dark_grey_on_white;
    }
  }

  &__header {
    gap: 0.25rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    cursor: pointer;

    &__arrow {
      display: flex;
      transition: transform 0.8s ease;
      transform: translateX(-50%) rotate(90deg);

      &--down {
        transform: rotate(-90deg);

        span {
          cursor: pointer;
        }
      }

      span {
        cursor: pointer;
      }
    }

    &--type {
      display: flex;
      justify-content: space-between;
    }

    &--tag {
      display: flex;
    }

    &--details {
      line-height: 1.5rem;
      display: flex;
      justify-content: space-between;
      font-weight: font.$weight--medium;
      flex-direction: column;
    }

    &--abo-number {
      font-family: font.$family--primary;
      font-style: normal;
      font-size: font.$size--medium;
      font-weight: font.$weight--regular;
      line-height: 1.125rem;
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    overflow: hidden;
    max-height: 32rem;
    transition: max-height 0.6s ease-in;

    &--toggled {
      max-height: 0;
    }
  }

  &__line {
    border: 0.063rem solid colors.$light_gray;
  }
}
