@use "sass:color";
@use "../../common/scss/colors";
@use "../../common/scss/mixins";

.maintenance {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: colors.$blue;
  height: 100vh;

  .icon-amway {
    background-color: colors.$blue;
    cursor: none;
  }
}
