@use "../../../../../common/scss/mixins";
@use "../../../../../common/scss/colors";
@use "../../../../../common/scss/font";

.GAR {
  &__title-link {
    padding-bottom: 0.3125rem;
  }

  &__lastMonthProcessed {
    font-size: font.$size--small;
    text-wrap: nowrap;
  }

  &--bold {
    font-weight: bold;
  }

  &__divider-spacing {
    padding: 0.6rem 0;
  }

  &__content-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);

    @include mixins.lg_s {
      font-size: font.$size--medium !important;
    }
  }

  &__row-label {
    padding: 0.3125rem 0;
    color: colors.$dark_grey_on_white;
  }

  &__row-value {
    padding: 0.3125rem 0;
    display: flex;
    text-align: right;
    color: colors.$amway_black;
    font-weight: bold;
    justify-content: flex-end;

    .awardProgressBadge__title {
      height: fit-content;
      text-wrap: pretty !important;
      text-align: center;

      @include mixins.lg_s {
        font-size: font.$size--medium !important;
      }
    }
  }
}
