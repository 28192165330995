.icon-dropdown-carrot {
  background: url("./svg/icon-dropdown-carrot.svg");
  background-repeat: no-repeat;
  display: block;
  height: 1.35rem;
  width: 1.125rem;
  cursor: pointer;
}

.icon-dropdown-carrot-blue {
  background: url("./svg/icon-dropdown-carrot-blue.svg");
  background-repeat: no-repeat;
  display: block;
  height: 1.35rem;
  width: 1.125rem;
  cursor: pointer;
}

.open {
  transform: rotate(-180deg);
}
