.IconMobilePhone {
  mask-repeat: no-repeat;
  display: inline-block;
  user-select: none;
  fill: black;
  height: 1.4rem;

  &__white {
    fill: white;
  }

  &__blue {
    fill: blue;
  }

  &__green {
    fill: green;
  }
}
